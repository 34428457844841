define("common/templates/components/cd-image", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable }}
  <div class="cd-image" ...attributes {{did-insert this.didInsert}}>
    {{#if this.usePictureElement}}
      <picture class={{@pictureClass}}>
        {{#if (and this.webPImagesEnabled @availableInWebP (not this.fallbackToOriginalSource))}}
          <source srcset={{update-file-extension @src "webp"}} type="image/webp">
        {{/if}}
        <img
          alt={{if @alt @alt ""}}
          class="cd-image--image {{@imageClass}}"
          onerror={{this.onError}}
          role={{@role}}
          src={{@src}}
          title={{@title}}
          width={{@width}}
          height={{@height}}
        >
      </picture>
    {{else}}
      <img
        alt={{if @alt @alt ""}}
        class="cd-image--image {{@imageClass}}"
        role={{@role}}
        src={{this.fallback}}
        title={{@title}}
        width={{@width}}
        height={{@height}}
      >
    {{/if}}
  </div>
  
  */
  {
    "id": "ZAzwXoBQ",
    "block": "[[[11,0],[24,0,\"cd-image\"],[17,1],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"usePictureElement\"]],[[[1,\"    \"],[10,\"picture\"],[15,0,[30,2]],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"webPImagesEnabled\"]],[30,3],[28,[37,3],[[30,0,[\"fallbackToOriginalSource\"]]],null]],null],[[[1,\"        \"],[10,\"source\"],[15,\"srcset\",[28,[37,4],[[30,4],\"webp\"],null]],[14,4,\"image/webp\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,\"img\"],[15,\"alt\",[52,[30,5],[30,5],\"\"]],[15,0,[29,[\"cd-image--image \",[30,6]]]],[15,\"onerror\",[30,0,[\"onError\"]]],[15,\"role\",[30,7]],[15,\"src\",[30,4]],[15,\"title\",[30,8]],[15,\"width\",[30,9]],[15,\"height\",[30,10]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"img\"],[15,\"alt\",[52,[30,5],[30,5],\"\"]],[15,0,[29,[\"cd-image--image \",[30,6]]]],[15,\"role\",[30,7]],[15,\"src\",[30,0,[\"fallback\"]]],[15,\"title\",[30,8]],[15,\"width\",[30,9]],[15,\"height\",[30,10]],[12],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@pictureClass\",\"@availableInWebP\",\"@src\",\"@alt\",\"@imageClass\",\"@role\",\"@title\",\"@width\",\"@height\"],false,[\"did-insert\",\"if\",\"and\",\"not\",\"update-file-extension\"]]",
    "moduleName": "common/templates/components/cd-image.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});