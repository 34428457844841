define("ember-svg-jar/inlined/prize-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"2273.5\" cy=\"2273.5\" r=\"2273\" fill=\"#fff\" stroke=\"#C4C4C4\"/><circle cx=\"2273.5\" cy=\"2273.5\" r=\"2183.5\" fill=\"#0082C0\"/><path d=\"M3817.47 3817.47a2183.668 2183.668 0 00473.32-708.38A2183.552 2183.552 0 004457 2273.5c0-286.74-56.48-570.67-166.21-835.59a2183.628 2183.628 0 00-473.32-708.378L2273.5 2273.5l1543.97 1543.97zM729.532 3817.47a2183.597 2183.597 0 01-473.323-708.38A2183.532 2183.532 0 0190 2273.5c0-286.74 56.478-570.67 166.209-835.59a2183.557 2183.557 0 01473.323-708.378L2273.5 2273.5 729.532 3817.47z\" fill=\"#005F8D\"/><text transform=\"translate(1805 433)\" fill=\"#fff\" style=\"white-space:pre\" font-family=\"Lato\" font-size=\"225\" font-weight=\"bold\" letter-spacing=\"0em\"><tspan x=\".153\" y=\"222.075\">10% OFF</tspan></text><text transform=\"rotate(90 1462.5 2651.5)\" fill=\"#fff\" style=\"white-space:pre\" font-family=\"Lato\" font-size=\"225\" font-weight=\"bold\" letter-spacing=\"0em\"><tspan x=\"576.153\" y=\"222.075\">25% OFF</tspan></text><text transform=\"rotate(-180 1714.5 2057)\" fill=\"#fff\" style=\"white-space:pre\" font-family=\"Lato\" font-size=\"225\" font-weight=\"bold\" letter-spacing=\"0em\"><tspan x=\"706.653\" y=\"222.075\">15% OFF</tspan></text><text transform=\"rotate(-90 1935.5 1502.5)\" fill=\"#fff\" style=\"white-space:pre\" font-family=\"Lato\" font-size=\"225\" font-weight=\"bold\" letter-spacing=\"0em\"><tspan x=\"706.653\" y=\"222.075\">20% OFF</tspan></text><circle cx=\"2273.5\" cy=\"2273.5\" r=\"1234.5\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2276.27 2293.68l1586.34 1586.34 35.36-35.36-1586.34-1586.34L3871.3 698.654l-35.36-35.355L2276.27 2222.97 707.976 654.674l-35.355 35.356L2240.92 2258.32 643.201 3856.04l35.356 35.35L2276.27 2293.68z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "4547",
      "height": "4547",
      "viewBox": "0 0 4547 4547",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});