define("common/templates/components/cd-site-search", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cd-site-search" ...attributes {{did-insert this.didInsert}}>
    <CdAutocomplete
      @ariaLabel={{@ariaLabel}}
      @changeOnEnter={{true}}
      @closeOnSelect={{false}}
      @class="cd-site-search {{@class}}"
      @closeOnType={{true}}
      @destination={{@destination}}
      @groupComponent="cd-site-search/power-select-group"
      @hasError={{@hasError}}
      @onChange={{this.handleOnChange}}
      @onFocus={{this.onFocus}}
      @onOpen={{this.onOpen}}
      @options={{this.results}}
      @noMatchesMessage={{false}}
      @placeholder={{@placeholder}}
      @registerAutocomplete={{this.registerAutocomplete}}
      @renderInPlace={{@renderInPlace}}
      @search={{this.search}}
      @searchEnabled={{false}}
      @selected={{@selected}}
      @triggerIcon="magnifying-glass"
      @triggerIconPrefix="far"
      as |result|
    >
      <div class="cd-site-search--{{result.type}}">
        <FaIcon class="cd-autocomplete--icon" @icon={{result.icon}} @prefix={{result.iconPrefix}}/>
        {{{highlight-match result.name this.searchText}}}
      </div>
    </CdAutocomplete>
  </div>
  
  */
  {
    "id": "nHxZqHYr",
    "block": "[[[11,0],[24,0,\"cd-site-search\"],[17,1],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@ariaLabel\",\"@changeOnEnter\",\"@closeOnSelect\",\"@class\",\"@closeOnType\",\"@destination\",\"@groupComponent\",\"@hasError\",\"@onChange\",\"@onFocus\",\"@onOpen\",\"@options\",\"@noMatchesMessage\",\"@placeholder\",\"@registerAutocomplete\",\"@renderInPlace\",\"@search\",\"@searchEnabled\",\"@selected\",\"@triggerIcon\",\"@triggerIconPrefix\"],[[30,2],true,false,[29,[\"cd-site-search \",[30,3]]],true,[30,4],\"cd-site-search/power-select-group\",[30,5],[30,0,[\"handleOnChange\"]],[30,0,[\"onFocus\"]],[30,0,[\"onOpen\"]],[30,0,[\"results\"]],false,[30,6],[30,0,[\"registerAutocomplete\"]],[30,7],[30,0,[\"search\"]],false,[30,8],\"magnifying-glass\",\"far\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[29,[\"cd-site-search--\",[30,9,[\"type\"]]]]],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"cd-autocomplete--icon\"]],[[\"@icon\",\"@prefix\"],[[30,9,[\"icon\"]],[30,9,[\"iconPrefix\"]]]],null],[1,\"\\n      \"],[2,[28,[37,3],[[30,9,[\"name\"]],[30,0,[\"searchText\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[9]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@ariaLabel\",\"@class\",\"@destination\",\"@hasError\",\"@placeholder\",\"@renderInPlace\",\"@selected\",\"result\"],false,[\"did-insert\",\"cd-autocomplete\",\"fa-icon\",\"highlight-match\"]]",
    "moduleName": "common/templates/components/cd-site-search.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});