define("common/templates/components/cd-enum-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CdSelect
    ...attributes
    @errorMessages={{@errorMessages}}
    @icon={{@icon}}
    @iconPrefix={{@iconPrefix}}
    @label={{@label}}
    @options={{this.options}}
    @placeholder={{@placeholder}}
    @selected={{this.currentValue}}
    @tooltip={{@tooltip}}
  />
  */
  {
    "id": "P3q8HI5t",
    "block": "[[[8,[39,0],[[17,1]],[[\"@errorMessages\",\"@icon\",\"@iconPrefix\",\"@label\",\"@options\",\"@placeholder\",\"@selected\",\"@tooltip\"],[[30,2],[30,3],[30,4],[30,5],[30,0,[\"options\"]],[30,6],[30,0,[\"currentValue\"]],[30,7]]],null]],[\"&attrs\",\"@errorMessages\",\"@icon\",\"@iconPrefix\",\"@label\",\"@placeholder\",\"@tooltip\"],false,[\"cd-select\"]]",
    "moduleName": "common/templates/components/cd-enum-select.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});