define("ember-cli-facebook-js-sdk/services/fb", ["exports", "@ember/object/evented", "@ember/service", "@ember/application", "@ember/polyfills", "rsvp", "@ember/runloop"], function (_exports, _evented, _service, _application, _polyfills, _rsvp, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = _service.default.extend(_evented.default, {
    fbInitPromise: null,
    locale: null,
    refreshToken: true,
    FBInit: function FBInit() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (this.fbInitPromise) {
        return this.fbInitPromise;
      }
      var ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      var initSettings = (0, _polyfills.assign)({}, ENV.FB || {}, options);

      // Detect language configuration and store it.
      var locale = initSettings.locale || 'en_US';
      this.locale = locale;
      if (ENV.FB && ENV.FB.skipInit) {
        this.fbInitPromise = (0, _rsvp.resolve)('skip init');
        return this.fbInitPromise;
      }
      var original = window.fbAsyncInit;
      if (!initSettings || !initSettings.appId || !initSettings.version) {
        return (0, _rsvp.reject)('No settings for init');
      }
      this.fbInitPromise = new _rsvp.Promise(function (resolve) {
        window.fbAsyncInit = function () {
          window.FB.init(initSettings);
          _runloop.run.next(null, resolve);
        };
        // URL for the SDK is built according to locale. Defaults to `en_US`.
        var scriptURL = "https://connect.facebook.net/".concat(locale, "/sdk.js");
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.src = scriptURL;
        head.appendChild(script);
      }).then(function () {
        if (original) {
          window.fbAsyncInit = original;
          window.fbAsyncInit();
          window.fbAsyncInit.hasRun = true;
        }
      });
      return this.fbInitPromise;
    },
    setAccessToken: function setAccessToken(token) {
      this.accessToken = token;
      this.trigger('fb.setAccessToken', token);
      return token;
    },
    loginWith: function loginWith(token) {
      console.warn('DEPRECATED: please, use setAccessToken instead');
      this.setAccessToken(token);
    },
    _api: function _api(path) {
      var method = 'GET';
      var parameters = {};
      var arg;
      if (!path) {
        return (0, _rsvp.reject)('Please, provide a path for your request');
      }
      switch (arguments.length) {
        case 2:
          arg = arguments[1];
          if (typeof arg === 'string') {
            method = arg;
          } else {
            parameters = arg;
          }
          break;
        case 3:
          method = arguments[1];
          parameters = arguments[2];
      }
      if (!parameters.access_token) {
        parameters = (0, _polyfills.assign)(parameters, {
          access_token: this.accessToken
        });
      }
      return this.FBInit().then(function () {
        return new _rsvp.Promise(function (resolve, reject) {
          window.FB.api(path, method, parameters, function (response) {
            if (response.error) {
              _runloop.run.next(null, reject, response.error);
              return;
            }
            _runloop.run.next(null, resolve, response);
          });
        });
      });
    },
    api: function api() {
      var _arguments = arguments,
        _this = this;
      return this._api.apply(this, arguments).catch(function (error) {
        if (_this.refreshToken && (error.code === 190 || error.code === 2500)) {
          console.debug('Trying to refresh Facebook session an re-do the Facebook API request');
          return _this.getLoginStatus().then(function (response) {
            if (response.status === 'connected') {
              _this.setAccessToken(response.authResponse.accessToken);
              return _this._api.apply(_this, _toConsumableArray(_arguments));
            }
            return (0, _rsvp.reject)(response);
          });
        }
        return (0, _rsvp.reject)(error);
      });
    },
    ui: function ui(params) {
      return this.FBInit().then(function () {
        return new _rsvp.Promise(function (resolve, reject) {
          window.FB.ui(params, function (response) {
            if (response && !response.error_code) {
              _runloop.run.next(null, resolve, response);
              return;
            }
            _runloop.run.next(null, reject, response);
          });
        });
      });
    },
    // Facebook Login Methods

    getLoginStatus: function getLoginStatus(forceRequest) {
      return this.FBInit().then(function () {
        return new _rsvp.Promise(function (resolve) {
          window.FB.getLoginStatus(function (response) {
            _runloop.run.next(null, resolve, response);
          }, forceRequest);
        });
      });
    },
    login: function login(scope) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var service = this;
      var params = {
        scope: scope,
        return_scopes: true
      };
      if (options) {
        params = (0, _polyfills.assign)({}, params, options);
      }
      return this.FBInit().then(function () {
        return new _rsvp.Promise(function (resolve, reject) {
          window.FB.login(function (response) {
            if (response.authResponse) {
              service.accessToken = response.authResponse.accessToken;
              _runloop.run.next(null, resolve, response);
            } else {
              _runloop.run.next(null, reject, response);
            }
          }, params);
        });
      });
    },
    logout: function logout() {
      return this.FBInit().then(function () {
        return new _rsvp.Promise(function (resolve) {
          window.FB.logout(function (response) {
            _runloop.run.next(null, resolve, response);
          });
        });
      });
    },
    getAuthResponse: function getAuthResponse() {
      return window.FB.getAuthResponse();
    },
    xfbml_parse: function xfbml_parse() {
      return this.FBInit().then(function () {
        return new _rsvp.Promise(function (resolve) {
          return window.FB.XFBML.parse(undefined, function () {
            _runloop.run.next(null, resolve, 'XFBML.parse');
          });
        });
      });
    }
  });
  _exports.default = _default;
});