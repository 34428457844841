define("common/templates/components/cd-switch", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cd-switch {{if this.inline 'cd-switch--inline'}}" ...attributes>
    {{#each this.options as |option index|}}
      <button
        class="cd-switch--option {{if
          (or
            (eq option this.selected)
            (eq (get option this.valueAttribute) this.selected)
            (and
              (eq index 0)
              (not this.selected)
            )
          ) "cd-switch--option--active"}}"
        type="button"
        {{on 'click' (fn (mut this.selected) (if this.valueAttribute (get option this.valueAttribute) option))}}
      >
        {{titleize (if this.labelAttribute (get option this.labelAttribute) option)}}
      </button>
    {{/each}}
  </div>
  
  */
  {
    "id": "WcVLeyob",
    "block": "[[[11,0],[16,0,[29,[\"cd-switch \",[52,[30,0,[\"inline\"]],\"cd-switch--inline\"]]]],[17,1],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"options\"]]],null]],null],null,[[[1,\"    \"],[11,\"button\"],[16,0,[29,[\"cd-switch--option \",[52,[28,[37,3],[[28,[37,4],[[30,2],[30,0,[\"selected\"]]],null],[28,[37,4],[[28,[37,5],[[30,2],[30,0,[\"valueAttribute\"]]],null],[30,0,[\"selected\"]]],null],[28,[37,6],[[28,[37,4],[[30,3],0],null],[28,[37,7],[[30,0,[\"selected\"]]],null]],null]],null],\"cd-switch--option--active\"]]]],[24,4,\"button\"],[4,[38,8],[\"click\",[28,[37,9],[[28,[37,10],[[30,0,[\"selected\"]]],null],[52,[30,0,[\"valueAttribute\"]],[28,[37,5],[[30,2],[30,0,[\"valueAttribute\"]]],null],[30,2]]],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,11],[[52,[30,0,[\"labelAttribute\"]],[28,[37,5],[[30,2],[30,0,[\"labelAttribute\"]]],null],[30,2]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2,3]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"option\",\"index\"],false,[\"if\",\"each\",\"-track-array\",\"or\",\"eq\",\"get\",\"and\",\"not\",\"on\",\"fn\",\"mut\",\"titleize\"]]",
    "moduleName": "common/templates/components/cd-switch.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});