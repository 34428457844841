define("ember-cli-qrcode/components/qr-code", ["exports", "@ember/component", "@ember/string", "@ember/object", "ember-cli-qrcode/system/qr-code", "ember-cli-qrcode/templates/components/qr-code"], function (_exports, _component, _string, _object, _qrCode, _qrCode2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = _component.default.extend({
    layout: _qrCode2.default,
    size: (0, _string.htmlSafe)('100%'),
    darkColor: '#000000',
    lightColor: '#FFFFFF',
    code: (0, _object.computed)('text', function () {
      return (0, _qrCode.default)(this.text);
    }),
    viewBox: (0, _object.computed)('code', function () {
      var nCount = this.code.getModuleCount();
      return (0, _string.htmlSafe)("0 0 ".concat(nCount, " ").concat(nCount));
    }),
    rows: (0, _object.computed)('code', function () {
      var code = this.code;
      var nCount = code.getModuleCount();
      return _toConsumableArray(Array(nCount)).map(function (_, row) {
        return _toConsumableArray(Array(nCount)).map(function (_, col) {
          return code.isDark(row, col);
        });
      });
    })
  });
  _exports.default = _default;
});