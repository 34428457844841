define("common/templates/components/cd-autocomplete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <PowerSelect
    @beforeOptionsComponent="cd-autocomplete/before-options"
    @destination={{@destination}}
    @dropdownClass="cd-autocomplete--dropdown"
    @extra={{hash
      ariaLabel=@ariaLabel
      changeOnEnter=@changeOnEnter
      closeOnType=@closeOnType
      resultsTitle=@resultsTitle
      triggerIcon=@triggerIcon
      triggerIconClass=@triggerIconClass
      triggerIconPrefix=@triggerIconPrefix
    }}
    @groupComponent={{@groupComponent}}
    @onBlur={{@onBlur}}
    @onFocus={{@onFocus}}
    @onChange={{this.handleChange}}
    @onClose={{this.handleClose}}
    @onOpen={{this.handleOpen}}
    @options={{@options}}
    @noMatchesMessage={{@noMatchesMessage}}
    @placeholder={{@placeholder}}
    @renderInPlace={{or this.renderInPlace (eq this.renderInPlace undefined)}}
    @registerAPI={{this.registerPowerSelect}}
    @search={{this.handleSearch}}
    @selected={{@selected}}
    @tabindex="-1"
    @triggerClass="cd-autocomplete {{@class}} {{if @hasError "cd-input--error"}}"
    @triggerComponent="cd-autocomplete/trigger"
    @typeAheadOptionMatcher={{this.noMatches}}
    as |result|
  >
    {{yield result}}
  </PowerSelect>
  */
  {
    "id": "nTJ0dxKM",
    "block": "[[[8,[39,0],null,[[\"@beforeOptionsComponent\",\"@destination\",\"@dropdownClass\",\"@extra\",\"@groupComponent\",\"@onBlur\",\"@onFocus\",\"@onChange\",\"@onClose\",\"@onOpen\",\"@options\",\"@noMatchesMessage\",\"@placeholder\",\"@renderInPlace\",\"@registerAPI\",\"@search\",\"@selected\",\"@tabindex\",\"@triggerClass\",\"@triggerComponent\",\"@typeAheadOptionMatcher\"],[\"cd-autocomplete/before-options\",[30,1],\"cd-autocomplete--dropdown\",[28,[37,1],null,[[\"ariaLabel\",\"changeOnEnter\",\"closeOnType\",\"resultsTitle\",\"triggerIcon\",\"triggerIconClass\",\"triggerIconPrefix\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]]],[30,9],[30,10],[30,11],[30,0,[\"handleChange\"]],[30,0,[\"handleClose\"]],[30,0,[\"handleOpen\"]],[30,12],[30,13],[30,14],[28,[37,2],[[30,0,[\"renderInPlace\"]],[28,[37,3],[[30,0,[\"renderInPlace\"]],[27]],null]],null],[30,0,[\"registerPowerSelect\"]],[30,0,[\"handleSearch\"]],[30,15],\"-1\",[29,[\"cd-autocomplete \",[30,16],\" \",[52,[30,17],\"cd-input--error\"]]],\"cd-autocomplete/trigger\",[30,0,[\"noMatches\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,19,[[30,18]]],[1,\"\\n\"]],[18]]]]]],[\"@destination\",\"@ariaLabel\",\"@changeOnEnter\",\"@closeOnType\",\"@resultsTitle\",\"@triggerIcon\",\"@triggerIconClass\",\"@triggerIconPrefix\",\"@groupComponent\",\"@onBlur\",\"@onFocus\",\"@options\",\"@noMatchesMessage\",\"@placeholder\",\"@selected\",\"@class\",\"@hasError\",\"result\",\"&default\"],false,[\"power-select\",\"hash\",\"or\",\"eq\",\"if\",\"yield\"]]",
    "moduleName": "common/templates/components/cd-autocomplete.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});