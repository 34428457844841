define("torii/lib/query-string", ["exports", "@ember/array", "@ember/string", "@ember/object"], function (_exports, _array, _string, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function isValue(value) {
    return value || value === false;
  }
  function getParamValue(obj, paramName, optional) {
    var camelizedName = (0, _string.camelize)(paramName),
      value = (0, _object.get)(obj, camelizedName);
    if (!optional) {
      if (!isValue(value) && isValue((0, _object.get)(obj, paramName))) {
        throw new Error('Use camelized versions of url params. (Did not find ' + '"' + camelizedName + '" property but did find ' + '"' + paramName + '".');
      }
      if (!isValue(value)) {
        throw new Error('Missing url param: "' + paramName + '". (Looked for: property named "' + camelizedName + '".');
      }
    }
    return isValue(value) ? encodeURIComponent(value) : undefined;
  }
  function getOptionalParamValue(obj, paramName) {
    return getParamValue(obj, paramName, true);
  }
  var _default = _object.default.extend({
    init: function init() {
      this.obj = this.provider;
      this.urlParams = (0, _array.A)(this.requiredParams.slice()).uniq();
      this.optionalUrlParams = (0, _array.A)(this.optionalParams ? this.optionalParams.slice() : []).uniq();
      this.optionalUrlParams.forEach(function (param) {
        if (this.urlParams.indexOf(param) > -1) {
          throw new Error("Required parameters cannot also be optional: '" + param + "'");
        }
      }, this);
    },
    toString: function toString() {
      var urlParams = this.urlParams,
        optionalUrlParams = this.optionalUrlParams,
        obj = this.obj,
        keyValuePairs = (0, _array.A)([]);
      urlParams.forEach(function (paramName) {
        var paramValue = getParamValue(obj, paramName);
        keyValuePairs.push([paramName, paramValue]);
      });
      optionalUrlParams.forEach(function (paramName) {
        var paramValue = getOptionalParamValue(obj, paramName);
        if (isValue(paramValue)) {
          keyValuePairs.push([paramName, paramValue]);
        }
      });
      return keyValuePairs.map(function (pair) {
        return pair.join('=');
      }).join('&');
    }
  });
  _exports.default = _default;
});