define("url/index", ["exports", "url/-anchor-url", "url/-node-url", "url/-url-search-params"], function (_exports, _anchorUrl, _nodeUrl, _urlSearchParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.URLSearchParams = void 0;
  _exports.needPolyfill = needPolyfill;
  var URL = window && window.URL;
  var URLSearchParams = window && window.URLSearchParams || _urlSearchParams.default;
  _exports.URLSearchParams = URLSearchParams;
  var isFastBoot = typeof FastBoot !== 'undefined';
  var hasURL = typeof URL === 'function';
  var testURL = hasURL && new URL('http://example.com');
  function needPolyfill() {
    return !isFastBoot && (!hasURL || !testURL.href || !Object.getOwnPropertyDescriptor(URL.prototype, 'search'));
  }
  if (isFastBoot) {
    URL = _nodeUrl.default;
    _exports.URLSearchParams = URLSearchParams = _nodeUrl.URLSearchParams || _urlSearchParams.default;
  } else if (needPolyfill()) {
    URL = _anchorUrl.default;
  } else if (hasURL && !testURL.searchParams) {
    (0, _urlSearchParams.upgradeClass)(URL);
  }
  var _default = URL;
  _exports.default = _default;
});