define("ember-cli-qrcode/system/util", ["exports", "ember-cli-qrcode/system/constants", "ember-cli-qrcode/system/math", "ember-cli-qrcode/system/polynomial"], function (_exports, _constants, _math, _polynomial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBCHDigit = getBCHDigit;
  _exports.getBCHTypeInfo = getBCHTypeInfo;
  _exports.getBCHTypeNumber = getBCHTypeNumber;
  _exports.getErrorCorrectPolynomial = getErrorCorrectPolynomial;
  _exports.getLengthInBits = getLengthInBits;
  _exports.getLostPoint = getLostPoint;
  _exports.getMask = getMask;
  _exports.getPatternPosition = getPatternPosition;
  var PATTERN_POSITION_TABLE = [[], [6, 18], [6, 22], [6, 26], [6, 30], [6, 34], [6, 22, 38], [6, 24, 42], [6, 26, 46], [6, 28, 50], [6, 30, 54], [6, 32, 58], [6, 34, 62], [6, 26, 46, 66], [6, 26, 48, 70], [6, 26, 50, 74], [6, 30, 54, 78], [6, 30, 56, 82], [6, 30, 58, 86], [6, 34, 62, 90], [6, 28, 50, 72, 94], [6, 26, 50, 74, 98], [6, 30, 54, 78, 102], [6, 28, 54, 80, 106], [6, 32, 58, 84, 110], [6, 30, 58, 86, 114], [6, 34, 62, 90, 118], [6, 26, 50, 74, 98, 122], [6, 30, 54, 78, 102, 126], [6, 26, 52, 78, 104, 130], [6, 30, 56, 82, 108, 134], [6, 34, 60, 86, 112, 138], [6, 30, 58, 86, 114, 142], [6, 34, 62, 90, 118, 146], [6, 30, 54, 78, 102, 126, 150], [6, 24, 50, 76, 102, 128, 154], [6, 28, 54, 80, 106, 132, 158], [6, 32, 58, 84, 110, 136, 162], [6, 26, 54, 82, 110, 138, 166], [6, 30, 58, 86, 114, 142, 170]];
  var G15 = 1 << 10 | 1 << 8 | 1 << 5 | 1 << 4 | 1 << 2 | 1 << 1 | 1 << 0;
  var G18 = 1 << 12 | 1 << 11 | 1 << 10 | 1 << 9 | 1 << 8 | 1 << 5 | 1 << 2 | 1 << 0;
  var G15_MASK = 1 << 14 | 1 << 12 | 1 << 10 | 1 << 4 | 1 << 1;
  function getBCHTypeInfo(data) {
    var d = data << 10;
    while (getBCHDigit(d) - getBCHDigit(G15) >= 0) {
      d ^= G15 << getBCHDigit(d) - getBCHDigit(G15);
    }
    return (data << 10 | d) ^ G15_MASK;
  }
  function getBCHTypeNumber(data) {
    var d = data << 12;
    while (getBCHDigit(d) - getBCHDigit(G18) >= 0) {
      d ^= G18 << getBCHDigit(d) - getBCHDigit(G18);
    }
    return data << 12 | d;
  }
  function getBCHDigit(data) {
    var digit = 0;
    while (data != 0) {
      digit++;
      data >>>= 1;
    }
    return digit;
  }
  function getPatternPosition(typeNumber) {
    return PATTERN_POSITION_TABLE[typeNumber - 1];
  }
  function getMask(maskPattern, i, j) {
    switch (maskPattern) {
      case _constants.MaskPattern.PATTERN000:
        return (i + j) % 2 == 0;
      case _constants.MaskPattern.PATTERN001:
        return i % 2 == 0;
      case _constants.MaskPattern.PATTERN010:
        return j % 3 == 0;
      case _constants.MaskPattern.PATTERN011:
        return (i + j) % 3 == 0;
      case _constants.MaskPattern.PATTERN100:
        return (Math.floor(i / 2) + Math.floor(j / 3)) % 2 == 0;
      case _constants.MaskPattern.PATTERN101:
        return i * j % 2 + i * j % 3 == 0;
      case _constants.MaskPattern.PATTERN110:
        return (i * j % 2 + i * j % 3) % 2 == 0;
      case _constants.MaskPattern.PATTERN111:
        return (i * j % 3 + (i + j) % 2) % 2 == 0;
      default:
        throw new Error('bad maskPattern:' + maskPattern);
    }
  }
  function getErrorCorrectPolynomial(errorCorrectLength) {
    var a = new _polynomial.default([1], 0);
    for (var i = 0; i < errorCorrectLength; i++) {
      a = a.multiply(new _polynomial.default([1, (0, _math.gexp)(i)], 0));
    }
    return a;
  }
  function getLengthInBits(mode, type) {
    if (1 <= type && type < 10) {
      switch (mode) {
        case _constants.Mode.MODE_NUMBER:
          return 10;
        case _constants.Mode.MODE_ALPHA_NUM:
          return 9;
        case _constants.Mode.MODE_8BIT_BYTE:
          return 8;
        case _constants.Mode.MODE_KANJI:
          return 8;
        default:
          throw new Error('mode:' + mode);
      }
    } else if (type < 27) {
      switch (mode) {
        case _constants.Mode.MODE_NUMBER:
          return 12;
        case _constants.Mode.MODE_ALPHA_NUM:
          return 11;
        case _constants.Mode.MODE_8BIT_BYTE:
          return 16;
        case _constants.Mode.MODE_KANJI:
          return 10;
        default:
          throw new Error('mode:' + mode);
      }
    } else if (type < 41) {
      switch (mode) {
        case _constants.Mode.MODE_NUMBER:
          return 14;
        case _constants.Mode.MODE_ALPHA_NUM:
          return 13;
        case _constants.Mode.MODE_8BIT_BYTE:
          return 16;
        case _constants.Mode.MODE_KANJI:
          return 12;
        default:
          throw new Error('mode:' + mode);
      }
    } else {
      throw new Error('type:' + type);
    }
  }
  function getLostPoint(qrCode) {
    var moduleCount = qrCode.getModuleCount();
    var lostPoint = 0;
    for (var row = 0; row < moduleCount; row++) {
      for (var col = 0; col < moduleCount; col++) {
        var sameCount = 0;
        var dark = qrCode.isDark(row, col);
        for (var r = -1; r <= 1; r++) {
          if (row + r < 0 || moduleCount <= row + r) {
            continue;
          }
          for (var c = -1; c <= 1; c++) {
            if (col + c < 0 || moduleCount <= col + c) {
              continue;
            }
            if (r == 0 && c == 0) {
              continue;
            }
            if (dark == qrCode.isDark(row + r, col + c)) {
              sameCount++;
            }
          }
        }
        if (sameCount > 5) {
          lostPoint += 3 + sameCount - 5;
        }
      }
    }
    for (var _row = 0; _row < moduleCount - 1; _row++) {
      for (var _col = 0; _col < moduleCount - 1; _col++) {
        var count = 0;
        if (qrCode.isDark(_row, _col)) count++;
        if (qrCode.isDark(_row + 1, _col)) count++;
        if (qrCode.isDark(_row, _col + 1)) count++;
        if (qrCode.isDark(_row + 1, _col + 1)) count++;
        if (count == 0 || count == 4) {
          lostPoint += 3;
        }
      }
    }
    for (var _row2 = 0; _row2 < moduleCount; _row2++) {
      for (var _col2 = 0; _col2 < moduleCount - 6; _col2++) {
        if (qrCode.isDark(_row2, _col2) && !qrCode.isDark(_row2, _col2 + 1) && qrCode.isDark(_row2, _col2 + 2) && qrCode.isDark(_row2, _col2 + 3) && qrCode.isDark(_row2, _col2 + 4) && !qrCode.isDark(_row2, _col2 + 5) && qrCode.isDark(_row2, _col2 + 6)) {
          lostPoint += 40;
        }
      }
    }
    for (var _col3 = 0; _col3 < moduleCount; _col3++) {
      for (var _row3 = 0; _row3 < moduleCount - 6; _row3++) {
        if (qrCode.isDark(_row3, _col3) && !qrCode.isDark(_row3 + 1, _col3) && qrCode.isDark(_row3 + 2, _col3) && qrCode.isDark(_row3 + 3, _col3) && qrCode.isDark(_row3 + 4, _col3) && !qrCode.isDark(_row3 + 5, _col3) && qrCode.isDark(_row3 + 6, _col3)) {
          lostPoint += 40;
        }
      }
    }
    var darkCount = 0;
    for (var _col4 = 0; _col4 < moduleCount; _col4++) {
      for (var _row4 = 0; _row4 < moduleCount; _row4++) {
        if (qrCode.isDark(_row4, _col4)) {
          darkCount++;
        }
      }
    }
    var ratio = Math.abs(100 * darkCount / moduleCount / moduleCount - 50) / 5;
    lostPoint += ratio * 10;
    return lostPoint;
  }
});