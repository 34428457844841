define("common/templates/components/cd-input-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cd-input--wrapper cd-input--toggle-wrapper {{@wrapperClass}}">
    <input
      ...attributes
      class="cd-input--toggle"
      id="cd-input--{{this.guid}}"
      checked={{this.checked}}
      onchange={{this.onChange}}
    />
  
    {{#if @label}}
      <label class="cd-input--toggle-label" for="cd-input--{{this.guid}}">
        {{#if @icon}}<FaIcon @icon={{@icon}} @prefix={{if @iconPrefix @iconPrefix "fal"}}/> {{/if}}{{@label}}
      </label>
    {{/if}}
  
    {{#if @errorMessages}}
      <span class="cd-input--component-errors">
        <FaIcon @icon="triangle-exclamation"/>
        {{join "," @errorMessages}}
      </span>
    {{/if}}
  </div>
  */
  {
    "id": "cafg1h2A",
    "block": "[[[10,0],[15,0,[29,[\"cd-input--wrapper cd-input--toggle-wrapper \",[30,1]]]],[12],[1,\"\\n  \"],[11,\"input\"],[17,2],[24,0,\"cd-input--toggle\"],[16,1,[29,[\"cd-input--\",[30,0,[\"guid\"]]]]],[16,\"checked\",[30,0,[\"checked\"]]],[16,\"onchange\",[30,0,[\"onChange\"]]],[12],[13],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"    \"],[10,\"label\"],[14,0,\"cd-input--toggle-label\"],[15,\"for\",[29,[\"cd-input--\",[30,0,[\"guid\"]]]]],[12],[1,\"\\n      \"],[41,[30,4],[[[8,[39,1],null,[[\"@icon\",\"@prefix\"],[[30,4],[52,[30,5],[30,5],\"fal\"]]],null],[1,\" \"]],[]],null],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,6],[[[1,\"    \"],[10,1],[14,0,\"cd-input--component-errors\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@icon\"],[\"triangle-exclamation\"]],null],[1,\"\\n      \"],[1,[28,[35,2],[\",\",[30,6]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@wrapperClass\",\"&attrs\",\"@label\",\"@icon\",\"@iconPrefix\",\"@errorMessages\"],false,[\"if\",\"fa-icon\",\"join\"]]",
    "moduleName": "common/templates/components/cd-input-toggle.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});