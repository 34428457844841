define("common/templates/components/cd-map-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="fa-layers fa-lg cd-map-icon" ...attributes>
    <FaIcon @fixedWidth={{true}} @icon="location-dot" @prefix="fas"  @transform="grow-3" class="lf-map-icon fa-inverse"/>
    <FaIcon @fixedWidth={{true}} @icon="location-dot" @prefix="fas"  @transform="shrink-6 up-1" class="lf-map-icon fa-inverse"/>
    <FaIcon @fixedWidth={{true}} @icon="location-dot" @prefix="fas" class="lf-map-icon"/>
    <span class="fa-layers-text lf-map-icon--counter">{{inc @index}}</span>
  </span>
  */
  {
    "id": "tkTMcdOI",
    "block": "[[[11,1],[24,0,\"fa-layers fa-lg cd-map-icon\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"lf-map-icon fa-inverse\"]],[[\"@fixedWidth\",\"@icon\",\"@prefix\",\"@transform\"],[true,\"location-dot\",\"fas\",\"grow-3\"]],null],[1,\"\\n  \"],[8,[39,0],[[24,0,\"lf-map-icon fa-inverse\"]],[[\"@fixedWidth\",\"@icon\",\"@prefix\",\"@transform\"],[true,\"location-dot\",\"fas\",\"shrink-6 up-1\"]],null],[1,\"\\n  \"],[8,[39,0],[[24,0,\"lf-map-icon\"]],[[\"@fixedWidth\",\"@icon\",\"@prefix\"],[true,\"location-dot\",\"fas\"]],null],[1,\"\\n  \"],[10,1],[14,0,\"fa-layers-text lf-map-icon--counter\"],[12],[1,[28,[35,1],[[30,2]],null]],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@index\"],false,[\"fa-icon\",\"inc\"]]",
    "moduleName": "common/templates/components/cd-map-icon.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});