define("common/templates/components/cd-icon-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <button type="button" class="cd-button--unstyled cd-icon-button" ...attributes>
    <FaIcon @icon={{@icon}} @prefix={{@prefix}}/>
    {{#if @label}}<div class="cd-icon-button--label">{{@label}}</div>{{/if}}
  </button>
  
  */
  {
    "id": "Lm/gki4B",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[24,0,\"cd-button--unstyled cd-icon-button\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@icon\",\"@prefix\"],[[30,2],[30,3]]],null],[1,\"\\n  \"],[41,[30,4],[[[10,0],[14,0,\"cd-icon-button--label\"],[12],[1,[30,4]],[13]],[]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@icon\",\"@prefix\",\"@label\"],false,[\"fa-icon\",\"if\"]]",
    "moduleName": "common/templates/components/cd-icon-button.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});