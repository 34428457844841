define("ember-cli-swiper/components/swiper-container", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/debug", "@ember/polyfills", "@ember/object/computed", "ember-cli-swiper/templates/components/swiper-container"], function (_exports, _component, _object, _runloop, _debug, _polyfills, _computed, _swiperContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } /* globals Swiper */
  var keys = Object.keys;
  var assign = Object.assign || _polyfills.assign;
  var EMBER_CLI_SWIPER_OPTIONS = ['options', 'registerAs', 'vertical', 'centered', 'updateFor', 'currentSlide'];
  var _default = _component.default.extend({
    layout: _swiperContainer.default,
    classNames: ['swiper-container'],
    /**
     * Swiper Instance
     * @private
     * @type {Boolean}
     */
    _swiper: false,
    /**
     * Proxies `Swiper.activeIndex`
     * @public
     * @type {Number}
     */
    currentSlide: 0,
    /**
     * Compared against `currentSlide`
     * @private
     * @type {Number}
     */
    _currentSlideInternal: 0,
    /**
     * User defined map of Swiper events
     * @type {Object}
     */
    events: null,
    /**
     * Abstraction to invoke `Swiper.update`
     * @public
     * @type {String}
     */
    updateFor: '',
    /**
     * Compared against `updateFor`
     * @private
     * @type {String}
     */
    _updateForInternal: '',
    /**
     * Render navigation controls
     * @public
     * @type {Boolean}
     */
    hasNavigation: (0, _computed.or)('options.navigation', 'navigation'),
    /**
     * Swiper next element class
     * @public
     * @type {String}
     */
    nextElClass: 'swiper-button-next',
    /**
     * Swiper previous element class
     * @public
     * @type {String}
     */
    prevElClass: 'swiper-button-prev',
    /**
     * Render pagination controls
     * @public
     * @type {Boolean}
     */
    hasPagination: (0, _computed.or)('options.pagination', 'pagination'),
    /**
     * Single Attribute options
     * @public
     * @type {Object}
     */
    options: null,
    /**
     * Get Swiper options from attributes
     * @private
     * @return {Object}
     */
    _getOptions: function _getOptions() {
      var attrs = _object.getProperties.apply(void 0, [this].concat(_toConsumableArray(keys(this.attrs)))); // eslint-disable-line ember/no-attrs-in-components
      var options = assign({}, this.get('options'), attrs);

      // Overwrite pagination element selector
      if (options.pagination) {
        var customPaginationEl = typeof options.pagination === 'string' && options.pagination // custom string selector
        || _typeof(options.pagination) === 'object' && options.pagination.el // custom `el` option selector
        || '';

        // Note:
        //  Never resolve user provided pagination configuration,
        //  which may not extend Object.prototype creating hard to
        //  debug issues within Swiper.
        options.pagination = assign({
          clickable: customPaginationEl ? true : false
        },
        // custom paginations must be clickable
        _typeof(options.pagination) === 'object' ? options.pagination : {}, {
          el: customPaginationEl || "#".concat(this.get('elementId'), " > .swiper-pagination")
        });
      }
      if (options.navigation) {
        if (_typeof(options.navigation) !== 'object') {
          options.navigation = {};
        }

        // Sync prev/next nav classes to custom options
        if (typeof options.navigation.prevEl === 'string') {
          this.set('prevElClass', options.navigation.prevEl.replace('.', ''));
        }
        if (typeof options.navigation.nextEl === 'string') {
          this.set('nextElClass', options.navigation.nextEl.replace('.', ''));
        }

        // Ensure `nextEl` & `prevEl` required options set
        // and that navigation inherits from Object.prototype
        options.navigation = assign({}, options.navigation, {
          nextEl: ".".concat(this.get('nextElClass')),
          prevEl: ".".concat(this.get('prevElClass'))
        });
      }
      if (options.vertical) {
        (false && (0, _debug.warn)('ember-cli-swiper option `direction` is ignored while `vertical` true', !options.direction, {
          id: 'ember-cli-swiper.direction-with-virtical'
        }));
        options.direction = 'vertical';
      }
      if (options.centered) {
        (false && (0, _debug.warn)('ember-cli-swiper option `centeredSlides` is ignored while `centered` true', !options.centeredSlides, {
          id: 'ember-cli-swiper.centered-with-centered-slides'
        }));
        options.centeredSlides = true;
      }

      // Allows `init` event to fire
      if (this.get('events.init')) {
        options.init = false;
      }

      /*
       Remove component-only
       configuration options from Swiper options
       */
      keys(options).forEach(function (k) {
        return EMBER_CLI_SWIPER_OPTIONS.indexOf(k) !== -1 && delete options[k];
      });
      return options;
    },
    /**
     * Userland fallback sugar for forcing swiper update
     * @public
     */
    forceUpdate: function forceUpdate() {
      this.get('_swiper').update();
      this.get('_swiper').slideTo(this.get('currentSlide'));
    },
    /**
     * Update `currentSlide` and trigger `onChange` event
     * @private
     * @param {Object} swiper - Swiper instance
     */
    _slideChanged: function _slideChanged(swiper) {
      var index;
      if (this.get('loop')) {
        index = parseInt(swiper.slides.parent().find('.swiper-slide-active').attr('data-swiper-slide-index'), 10);
      } else {
        index = swiper.realIndex;
      }
      this.set('_currentSlideInternal', index);
      this.set('currentSlide', index);
      this.get('onChange')(swiper.slides[swiper.realIndex]);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      /*
       Data-down Swiper slide activation
       */
      if (this.get('currentSlide') !== this.get('_currentSlideInternal')) {
        var index = this.get('currentSlide');
        if (this.get('loop')) {
          var swiper = this.get('_swiper');
          index = swiper.slides.parent().find("[data-swiper-slide-index=\"".concat(this.get('currentSlide'), "\"]")).prevAll().length;
        }
        this.get('_swiper').slideTo(index);
        this.set('_currentSlideInternal', this.get('currentSlide'));
      }

      /*
       Trigger `update()` of swiper
       */
      if (this.get('updateFor') !== this.get('_updateForInternal')) {
        (0, _runloop.once)(this.get('_swiper'), 'update');
        this.set('_updateForInternal', this.get('updateFor'));
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('registerAs', this);
      var swiperOptions = assign({
        initialSlide: this.get('currentSlide')
      }, this._getOptions());
      var transitionEvent = this.get('loop') ? 'slideChangeTransitionEnd' : 'slideChange';
      var instance = this.set('_swiper', new Swiper(this.element, swiperOptions));
      instance.on(transitionEvent, this._slideChanged.bind(this, instance));

      // Subscribe configured actions as Swiper events
      keys(this.get('events') || {}).forEach(function (evt) {
        return instance.on(evt, _this.get("events.".concat(evt)));
      });

      // Manual initalization when user requires `init` event handling
      if (swiperOptions.init === false) {
        instance.init();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var instance = this.get('_swiper');
      if (instance) {
        instance.off('slideChangeTransitionEnd');
        instance.destroy();
        instance = this.set('_swiper', null);
      }
    },
    /**
     * On Swiper Slide change
     * @public
     * @param {Swiper.Slide} swiperSlide
     */
    onChange: function onChange() {} /* swiperSlide */
  });
  _exports.default = _default;
});