define("ember-cli-qrcode/templates/components/qr-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2CdarNt+",
    "block": "[[[10,\"svg\"],[15,\"width\",[36,0]],[15,\"height\",[36,0]],[15,\"viewBox\",[36,1]],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"rect\"],[15,\"fill\",[36,2]],[14,\"width\",\"100%\"],[14,\"height\",\"100%\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[15,\"fill\",[36,3]],[14,\"width\",\"1\"],[14,\"height\",\"1\"],[15,1,[29,[\"template-\",[36,4]]]],[12],[13],[1,\"\\n\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[42,[28,[37,6],[[28,[37,6],[[30,1]],null]],null],null,[[[41,[30,3],[[[1,\"        \"],[10,\"use\"],[15,\"x\",[30,4]],[15,\"y\",[30,2]],[15,\"xlink:href\",[29,[\"#template-\",[36,4]]],\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n\"]],[]],null]],[3,4]],null]],[1,2]],null],[13],[1,\"\\n\"]],[\"cols\",\"y\",\"visible\",\"x\"],false,[\"size\",\"viewBox\",\"lightColor\",\"darkColor\",\"elementId\",\"each\",\"-track-array\",\"rows\",\"if\"]]",
    "moduleName": "ember-cli-qrcode/templates/components/qr-code.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});