define("ember-cli-string-helpers/helpers/trim", ["exports", "@ember/component/helper", "ember-cli-string-helpers/utils/trim", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _helper, _trim, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trim = _exports.default = void 0;
  var trim = (0, _createStringHelper.default)(_trim.default);
  _exports.trim = trim;
  var _default = (0, _helper.helper)(trim);
  _exports.default = _default;
});