define("torii/routing/application-route-mixin", ["exports", "@ember/object/mixin", "torii/configuration"], function (_exports, _mixin, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _mixin.default.create({
    beforeModel: function beforeModel(transition) {
      var route = this;
      var superBefore = this._super.apply(this, arguments);
      if (superBefore && superBefore.then) {
        return superBefore.then(function () {
          return route.checkLogin(transition);
        });
      } else {
        return route.checkLogin(transition);
      }
    },
    checkLogin: function checkLogin() {
      var configuration = (0, _configuration.getConfiguration)();
      return this.get(configuration.sessionServiceName).fetch().catch(function () {
        // no-op, cause no session is ok
      });
    }
  });
  _exports.default = _default;
});