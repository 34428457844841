define("common/templates/components/cd-form-enum", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CdEnumSelect
    ...attributes
    @enum={{this.enum}}
    @errorMessages={{this.errorMessages}}
    @icon={{@icon}}
    @iconPrefix={{@iconPrefix}}
    @label={{@label}}
    @placeholder={{@placeholder}}
    @selected={{@selected}}
    @tooltip={{this.tooltip}}
  />
  */
  {
    "id": "qQ98DW1+",
    "block": "[[[8,[39,0],[[17,1]],[[\"@enum\",\"@errorMessages\",\"@icon\",\"@iconPrefix\",\"@label\",\"@placeholder\",\"@selected\",\"@tooltip\"],[[30,0,[\"enum\"]],[30,0,[\"errorMessages\"]],[30,2],[30,3],[30,4],[30,5],[30,6],[30,0,[\"tooltip\"]]]],null]],[\"&attrs\",\"@icon\",\"@iconPrefix\",\"@label\",\"@placeholder\",\"@selected\"],false,[\"cd-enum-select\"]]",
    "moduleName": "common/templates/components/cd-form-enum.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});