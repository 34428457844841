define("common/templates/components/cd-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-yield-only --}}
  {{yield}}
  */
  {
    "id": "PIKKEx0T",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "common/templates/components/cd-input.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});