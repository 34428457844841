define("common/templates/components/cd-form-credit-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CdInputText
    ...attributes
    @errorMessages={{this.errorMessages}}
    @icon="credit-card-front"
    @iconPrefix={{@iconPrefix}}
    @label={{@label}}
    @pattern="\d{4} \d{4} \d{4} \d{3,4}"
    @tooltip={{this.tooltip}}
    @type="tel"
    @value={{this.value}}
    placeholder="XXXX XXXX XXXX XXXX"
    title="Credit Card Number"
  />
  */
  {
    "id": "oUj4UaSG",
    "block": "[[[8,[39,0],[[17,1],[24,\"placeholder\",\"XXXX XXXX XXXX XXXX\"],[24,\"title\",\"Credit Card Number\"]],[[\"@errorMessages\",\"@icon\",\"@iconPrefix\",\"@label\",\"@pattern\",\"@tooltip\",\"@type\",\"@value\"],[[30,0,[\"errorMessages\"]],\"credit-card-front\",[30,2],[30,3],\"\\\\d{4} \\\\d{4} \\\\d{4} \\\\d{3,4}\",[30,0,[\"tooltip\"]],\"tel\",[30,0,[\"value\"]]]],null]],[\"&attrs\",\"@iconPrefix\",\"@label\"],false,[\"cd-input-text\"]]",
    "moduleName": "common/templates/components/cd-form-credit-card.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});