define("ember-cli-swiper/components/swiper-slide", ["exports", "@ember/component", "ember-cli-swiper/templates/components/swiper-slide"], function (_exports, _component, _swiperSlide) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _swiperSlide.default,
    classNames: ['swiper-slide']
  });
  _exports.default = _default;
});