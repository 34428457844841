define("ember-cli-swiper/templates/components/swiper-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nfmTslWQ",
    "block": "[[[10,0],[14,0,\"swiper-wrapper\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"slide\"],[[50,\"swiper-slide\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasPagination\"]],[[[1,\"  \"],[10,0],[14,0,\"swiper-pagination\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hasNavigation\"]],[[[1,\"  \"],[10,0],[15,0,[30,0,[\"nextElClass\"]]],[12],[13],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"prevElClass\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"if\"]]",
    "moduleName": "ember-cli-swiper/templates/components/swiper-container.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});