define("common/templates/components/cd-textarea", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cd-input--wrapper">
    <textarea ...attributes class="cd-textarea{{if this.errorMessages " cd-input--error"}}{{if this.class (concat " " this.class)}}" id="cd-input--{{this.guid}}" oninput={{this.onInput}}>{{@value}}</textarea>
  
    {{#if @label}}
      <label class="cd-input--label" for="cd-input--{{this.guid}}">
        {{#if @icon}}<FaIcon @icon={{@icon}} @prefix={{if @iconPrefix @iconPrefix "fal"}}/> {{/if}}{{@label}}
      </label>
    {{/if}}
  
    {{#if (eq @type "password")}}
      <div class="cd-form-input--right" role="button" onclick={{fn (toggle "currentType" this "password" "text")}}>
        {{if (eq this.currentType "password") "show" "hide"}}
      </div>
    {{else if (and @tooltip @tooltip.label @tooltip.tip)}}
      <LfToolTip
        @horizontalPosition="right"
        @label={{@tooltip.label}}
        @tip={{@tooltip.tip}}
        @triggerClass="lf-tool-tip--input-trigger"
      />
    {{/if}}
  
    {{#if @errorMessages}}
      <span class="cd-input--component-errors">
        <FaIcon @icon="triangle-exclamation"/>
        {{join "," @errorMessages}}
      </span>
    {{/if}}
  </div>
  
  */
  {
    "id": "NJ5UQcAg",
    "block": "[[[10,0],[14,0,\"cd-input--wrapper\"],[12],[1,\"\\n  \"],[11,\"textarea\"],[17,1],[16,0,[29,[\"cd-textarea\",[52,[30,0,[\"errorMessages\"]],\" cd-input--error\"],[52,[30,0,[\"class\"]],[28,[37,1],[\" \",[30,0,[\"class\"]]],null]]]]],[16,1,[29,[\"cd-input--\",[30,0,[\"guid\"]]]]],[16,\"oninput\",[30,0,[\"onInput\"]]],[12],[1,[30,2]],[13],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"    \"],[10,\"label\"],[14,0,\"cd-input--label\"],[15,\"for\",[29,[\"cd-input--\",[30,0,[\"guid\"]]]]],[12],[1,\"\\n      \"],[41,[30,4],[[[8,[39,2],null,[[\"@icon\",\"@prefix\"],[[30,4],[52,[30,5],[30,5],\"fal\"]]],null],[1,\" \"]],[]],null],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,3],[[30,6],\"password\"],null],[[[1,\"    \"],[10,0],[14,0,\"cd-form-input--right\"],[14,\"role\",\"button\"],[15,\"onclick\",[28,[37,4],[[28,[37,5],[\"currentType\",[30,0],\"password\",\"text\"],null]],null]],[12],[1,\"\\n      \"],[1,[52,[28,[37,3],[[30,0,[\"currentType\"]],\"password\"],null],\"show\",\"hide\"]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,6],[[30,7],[30,7,[\"label\"]],[30,7,[\"tip\"]]],null],[[[1,\"    \"],[8,[39,7],null,[[\"@horizontalPosition\",\"@label\",\"@tip\",\"@triggerClass\"],[\"right\",[30,7,[\"label\"]],[30,7,[\"tip\"]],\"lf-tool-tip--input-trigger\"]],null],[1,\"\\n  \"]],[]],null]],[]]],[1,\"\\n\"],[41,[30,8],[[[1,\"    \"],[10,1],[14,0,\"cd-input--component-errors\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@icon\"],[\"triangle-exclamation\"]],null],[1,\"\\n      \"],[1,[28,[35,8],[\",\",[30,8]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@value\",\"@label\",\"@icon\",\"@iconPrefix\",\"@type\",\"@tooltip\",\"@errorMessages\"],false,[\"if\",\"concat\",\"fa-icon\",\"eq\",\"fn\",\"toggle\",\"and\",\"lf-tool-tip\",\"join\"]]",
    "moduleName": "common/templates/components/cd-textarea.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});