define("common/templates/components/cd-form-phone", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CdInputText
    ...attributes
    @errorMessages={{this.errorMessages}}
    @icon="phone"
    @iconPrefix={{@iconPrefix}}
    @label={{@label}}
    @labelClass={{@labelClass}}
    @pattern="\(\[2-9]d{2}\) [2-9]d{2}\-\d{4}"
    @tooltip={{this.tooltip}}
    @type="tel"
    @value={{this.value}}
    placeholder="(XXX) XXX-XXXX"
    title="10-digit phone number"
  />
  */
  {
    "id": "onTLLr5L",
    "block": "[[[8,[39,0],[[17,1],[24,\"placeholder\",\"(XXX) XXX-XXXX\"],[24,\"title\",\"10-digit phone number\"]],[[\"@errorMessages\",\"@icon\",\"@iconPrefix\",\"@label\",\"@labelClass\",\"@pattern\",\"@tooltip\",\"@type\",\"@value\"],[[30,0,[\"errorMessages\"]],\"phone\",[30,2],[30,3],[30,4],\"\\\\(\\\\[2-9]d{2}\\\\) [2-9]d{2}\\\\-\\\\d{4}\",[30,0,[\"tooltip\"]],\"tel\",[30,0,[\"value\"]]]],null]],[\"&attrs\",\"@iconPrefix\",\"@label\",\"@labelClass\"],false,[\"cd-input-text\"]]",
    "moduleName": "common/templates/components/cd-form-phone.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});