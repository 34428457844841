define("ember-sticky-element/components/sticky-element/trigger", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "ember-in-viewport"], function (_exports, _component, _object, _runloop, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_emberInViewport.default, {
    classNameBindings: ['typeClass'],
    classNames: ['sticky-element__trigger'],
    /**
     * @property type
     * @type {string}
     * @default 'top'
     * @public
     */
    type: 'top',
    /**
     * @property offset
     * @type {number}
     * @public
     */
    offset: 0,
    /**
     * @property typeClass
     * @type string
     * @private
     */
    typeClass: (0, _object.computed)('type', function () {
      return "sticky-element__trigger--".concat(this.get('type'));
    }),
    _lastTop: null,
    /**
     * Action when trigger enters viewport
     *
     * @event enter
     * @public
     */
    /**
     * Action when trigger exits viewport
     *
     * @event exit
     * @param {Boolean} top True if element left the viewport from the top
     * @public
     */
    isBeforeViewport: function isBeforeViewport() {
      var offset = this.get('type') === 'top' ? this.get('offset') : 0;
      return this.get('element').getBoundingClientRect().top - offset < 0;
    },
    didEnterViewport: function didEnterViewport() {
      this.enter();
    },
    didExitViewport: function didExitViewport() {
      this.exit();
    },
    /**
     * @method updateViewportOptions
     * @private
     */
    updateViewportOptions: function updateViewportOptions() {
      var viewportTolerance = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      };
      viewportTolerance[this.get('type')] = -this.get('offset');
      (0, _object.setProperties)(this, {
        viewportSpy: true,
        viewportEnabled: true,
        viewportTolerance: viewportTolerance
      });
      this.updateIntersectionObserver();
    },
    /**
     * Updates intersectionObserver after options have changed
     *
     * @method updateIntersectionObserver
     * @private
     */
    updateIntersectionObserver: function updateIntersectionObserver() {
      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(this.element);
        this._setViewportEntered();
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.updateViewportOptions();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.registerElement(this.element);
    },
    _onOffsetChange: (0, _object.observer)('offset', function () {
      (0, _runloop.scheduleOnce)('afterRender', this, this.updateViewportOptions);
    }),
    _bindScrollDirectionListener: function _bindScrollDirectionListener() {},
    _unbindScrollDirectionListener: function _unbindScrollDirectionListener() {},
    /**
     * Override ember-in-viewport method to trigger event also when trigger has moved from below viewport to on top
     * of viewport without triggering didEnterViewport because of too fast scroll movement
     *
     * @method _triggerDidAccessViewport
     * @param hasEnteredViewport
     * @private
     */
    _triggerDidAccessViewport: function _triggerDidAccessViewport() {
      var hasEnteredViewport = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var viewportEntered = this.get('viewportEntered');
      var didEnter = !viewportEntered && hasEnteredViewport;
      var didLeave = viewportEntered && !hasEnteredViewport;
      var lastTop = this._lastTop;
      this._lastTop = this.isBeforeViewport();
      if (!didEnter && !didLeave) {
        if (lastTop !== this._lastTop) {
          this._super(true);
          this._super(false);
        }
      } else {
        this._super(hasEnteredViewport);
      }
    }
  });
  _exports.default = _default;
});