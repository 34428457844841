define("common/templates/components/cd-map-marker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Node>
    {{#let @map.marker as |Marker|}}
      <Marker
        @lat={{@location.latitude}}
        @lng={{@location.longitude}}
        @icon={{div-icon html=this.destinationElement iconAnchor=@iconAnchor iconSize=@iconSize className="cd-map-marker"}}
        @zIndexOffset={{@zIndexOffset}}
        @riseOnHover={{true}}
        @onPopupopen={{this.onPopupOpen}}
        @onPopupclose={{this.onPopupClose}}
        as |marker|
      >
        {{yield (hash popup=marker.popup)}}
      </Marker>
    {{/let}}
  
    {{#if this.destinationElement}}
      {{#in-element this.destinationElement}}
        {{yield (hash icon=(component "cd-blank"))}}
      {{/in-element}}
    {{/if}}
  </Node>
  
  
  
  */
  {
    "id": "gOgjTcEd",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[44,[[30,1,[\"marker\"]]],[[[1,\"    \"],[8,[30,2],null,[[\"@lat\",\"@lng\",\"@icon\",\"@zIndexOffset\",\"@riseOnHover\",\"@onPopupopen\",\"@onPopupclose\"],[[30,3,[\"latitude\"]],[30,3,[\"longitude\"]],[28,[37,2],null,[[\"html\",\"iconAnchor\",\"iconSize\",\"className\"],[[30,0,[\"destinationElement\"]],[30,4],[30,5],\"cd-map-marker\"]]],[30,6],true,[30,0,[\"onPopupOpen\"]],[30,0,[\"onPopupClose\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[18,8,[[28,[37,4],null,[[\"popup\"],[[30,7,[\"popup\"]]]]]]],[1,\"\\n    \"]],[7]]]]],[1,\"\\n\"]],[2]]],[1,\"\\n\"],[41,[30,0,[\"destinationElement\"]],[[[40,[[[1,\"      \"],[18,8,[[28,[37,4],null,[[\"icon\"],[[50,\"cd-blank\",0,null,null]]]]]],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,7],[[30,0,[\"destinationElement\"]]],null]]],[]],null]],[]]]]],[1,\"\\n\\n\\n\"]],[\"@map\",\"Marker\",\"@location\",\"@iconAnchor\",\"@iconSize\",\"@zIndexOffset\",\"marker\",\"&default\"],false,[\"node\",\"let\",\"div-icon\",\"yield\",\"hash\",\"if\",\"in-element\",\"-in-el-null\",\"component\"]]",
    "moduleName": "common/templates/components/cd-map-marker.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});