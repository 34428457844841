define("ember-cli-clipboard/components/copy-button", ["exports", "@ember/component", "@ember/object", "ember-cli-clipboard/templates/components/copy-button"], function (_exports, _component, _object, _copyButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var CLIPBOARD_EVENTS = ['success', 'error'];
  var _default = _component.default.extend({
    layout: _copyButton.default,
    tagName: 'button',
    classNames: ['copy-btn'],
    attributeBindings: ['clipboardText:data-clipboard-text', 'clipboardTarget:data-clipboard-target', 'clipboardAction:data-clipboard-action', 'buttonType:type', 'disabled', 'aria-label', 'title'],
    /**
     * @property {String} buttonType - type attribute for button element
     */
    buttonType: 'button',
    /**
     * @property {Boolean} disabled - disabled state for button element
     */
    disabled: false,
    /**
     * If true - scope event listener to this element
     * If false - scope event listener to document.body (ClipboardJS)
     * @property {Boolean} delegateClickEvent
     */
    delegateClickEvent: true,
    /**
     * Creates new `ClipboardJS` instance
     * @method _createClipboard
     * @private
     * @returns {Object} newly created ClipboardJS object
     */
    _createClipboard: function _createClipboard() {
      var text = this.clipboardText;
      var trigger = this.delegateClickEvent ? "#".concat(this.elementId) : this.element;
      return new window.ClipboardJS(trigger, {
        text: typeof text === 'function' ? text : undefined
      });
    },
    /**
     * Registers Ember Actions with ClipboardJS events
     * @method _registerActions
     * @private
     * @param {Object} clipboard - ClipboardJS object
     * @returns {Void}
     */
    _registerActions: function _registerActions(clipboard) {
      var _arguments = arguments,
        _this = this;
      CLIPBOARD_EVENTS.forEach(function (event) {
        clipboard.on(event, function () {
          if (!_this.disabled) {
            var action = _this[event] || function () {};
            if (typeof action === 'string') {
              // eslint-disable-next-line ember/closure-actions
              _this.sendAction.apply(_this, [action].concat(_toConsumableArray(_arguments)));
            } else {
              action.apply(void 0, _toConsumableArray(_arguments));
            }
          }
        });
      });
    },
    /**
     * Registers ClipboardJS object with component
     * @method _registerClipboard
     * @private
     * @returns {Void}
     */
    _registerClipboard: function _registerClipboard() {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
      var clipboard = this._createClipboard();
      this._registerActions(clipboard);
      (0, _object.set)(this, 'clipboard', clipboard);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._registerClipboard();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this._registerClipboard();
    },
    willDestroyElement: function willDestroyElement() {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
    }
  });
  _exports.default = _default;
});