define("common/templates/components/cd-form-submit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    ...attributes
    disabled={{or @disabled @form.isSaving}}
    type="submit"
  >
    {{#if @form.isSaving}}
      {{if (eq @savingText undefined) "Saving..." @savingText}}
      <FaIcon @icon="spinner" @spin={{true}}/>
    {{else}}
      {{yield}}
    {{/if}}
  </button>
  */
  {
    "id": "5JFQ8N2d",
    "block": "[[[11,\"button\"],[17,1],[16,\"disabled\",[28,[37,0],[[30,2],[30,3,[\"isSaving\"]]],null]],[24,4,\"submit\"],[12],[1,\"\\n\"],[41,[30,3,[\"isSaving\"]],[[[1,\"    \"],[1,[52,[28,[37,2],[[30,4],[27]],null],\"Saving...\",[30,4]]],[1,\"\\n    \"],[8,[39,3],null,[[\"@icon\",\"@spin\"],[\"spinner\",true]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,5,null],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@disabled\",\"@form\",\"@savingText\",\"&default\"],false,[\"or\",\"if\",\"eq\",\"fa-icon\",\"yield\"]]",
    "moduleName": "common/templates/components/cd-form-submit.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});