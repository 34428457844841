define("torii/compat/get-router-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getRouterInstance;
  function getRouterInstance(appInstance) {
    // backwards compat for Ember < 2.0
    return appInstance.get('router') || appInstance.lookup('router:main');
  }
});