define("ember-keyboard/mixins/ember-keyboard", ["exports", "@ember/service", "@ember/object/evented", "@ember/object/mixin", "ember-keyboard/utils/handle-key-event", "@ember/debug"], function (_exports, _service, _evented, _mixin, _handleKeyEvent, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } /* eslint-disable ember/no-new-mixins */
  var _default = _mixin.default.create(_evented.default, {
    keyboardPriority: 0,
    keyboard: (0, _service.inject)(),
    init: function init() {
      (false && !(false) && (0, _debug.deprecate)('`EKMixin` of ember-keyboard is deprecated. Please use the @keyResponder decorator instead.', false, {
        id: 'ember-keyboard.ember-keyboard-mixin',
        for: 'ember-keyboard',
        since: '6.0.2',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#ember-keyboard-mixin'
      }));
      this.keyboard.register(this);
      return this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.keyboard.unregister(this);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.keyboard.unregister(this);
    },
    // These next two methods adapt this mixin to conform to the new responder API.
    // In the future, once we have good alternatives, we expect all of this addon's
    // mixins to be deprecated and removed, but for now this will let it execute
    // without triggering deprecation warnings.
    canHandleKeyboardEvent: function canHandleKeyboardEvent(event) {
      var _iterator = _createForOfIteratorHelper((0, _handleKeyEvent.getListenerNames)(event)),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var listenerName = _step.value;
          if (this.has(listenerName)) {
            return true;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return false;
    },
    handleKeyboardEvent: function handleKeyboardEvent(event, ekEvent) {
      (0, _handleKeyEvent.triggerViaLegacyResponderApi)(this, event, ekEvent);
    }
  });
  _exports.default = _default;
});