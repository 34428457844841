define("ember-sticky-element/components/sticky-element", ["exports", "ember", "@ember/object/computed", "@ember/string", "@ember/component", "@ember/object", "@ember/runloop", "ember-sticky-element/templates/components/sticky-element"], function (_exports, _ember, _computed, _string, _component, _object, _runloop, _stickyElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var testing = _ember.default.testing;
  function elementPosition(element, offseTop, offsetBottom) {
    var top = element.getBoundingClientRect().top;
    if (top - offseTop < 0) {
      return 'top';
    }
    if (top + element.offsetHeight + offsetBottom <= window.innerHeight) {
      return 'in';
    }
    return 'bottom';
  }
  var _default = _component.default.extend({
    layout: _stickyElement.default,
    classNames: ['sticky-element-container'],
    attributeBindings: ['style'],
    /**
     * The offset from the top of the viewport when to start sticking to the top
     *
     * @property top
     * @type {number}
     * @default 0
     * @public
     */
    top: 0,
    /**
     * The offset from the parents bottom edge when to start sticking to the bottom of the parent
     * When `null` (default) sticking to the bottom is disabled. Use 0 or any other appropriate offset to enable it.
     *
     * @property bottom
     * @type {boolean|null}
     * @public
     */
    bottom: null,
    /**
     * Set to false to disable sticky behavior
     *
     * @property enabled
     * @type {boolean}
     * @default true
     * @public
     */
    enabled: true,
    /**
     * The class name set on the element container.
     *
     * @property containerClassName
     * @type {string|null}
     * @default 'sticky-element'
     * @public
     */
    containerClassName: 'sticky-element',
    /**
     * The class name set on the element container when it is sticked.
     *
     * @property containerStickyClassName
     * @type {string|null}
     * @default 'sticky-element--sticky'
     * @public
     */
    containerStickyClassName: 'sticky-element--sticky',
    /**
     * The class name set on the element container when it is sticked to top.
     *
     * @property containerStickyTopClassName
     * @type {string|null}
     * @default 'sticky-element--sticky-top'
     * @public
     */
    containerStickyTopClassName: 'sticky-element--sticky-top',
    /**
     * The class name set on the element container when it is sticked to bottom.
     *
     * @property containerStickyBottomClassName
     * @type {string|null}
     * @default 'sticky-element--sticky-bottom'
     * @public
     */
    containerStickyBottomClassName: 'sticky-element--sticky-bottom',
    /**
     * @property isSticky
     * @type {boolean}
     * @readOnly
     * @private
     */
    isSticky: (0, _computed.or)('isStickyTop', 'isStickyBottom').readOnly(),
    /**
     * @property isStickyTop
     * @type {boolean}
     * @readOnly
     * @private
     */
    isStickyTop: (0, _object.computed)('enabled', 'parentTop', 'parentBottom', 'isStickyBottom', function () {
      return this.get('enabled') && this.get('parentTop') === 'top' && !this.get('isStickyBottom');
    }).readOnly(),
    /**
     * @property isStickyBottom
     * @type {boolean}
     * @readOnly
     * @private
     */
    isStickyBottom: (0, _object.computed)('enabled', 'parentBottom', 'stickToBottom', function () {
      return this.get('enabled') && this.get('parentBottom') !== 'bottom' && this.get('stickToBottom');
    }).readOnly(),
    /**
     * @property parentTop
     * @type {string}
     * @private
     */
    parentTop: 'bottom',
    /**
     * @property parentBottom
     * @type {string}
     * @private
     */
    parentBottom: 'bottom',
    /**
     * @property ownHeight
     * @type {number}
     * @private
     */
    ownHeight: 0,
    /**
     * @property ownWidth
     * @type {number}
     * @private
     */
    ownWidth: 0,
    /**
     * @property stickToBottom
     * @type {boolean}
     * @readOnly
     * @private
     */
    stickToBottom: (0, _computed.notEmpty)('bottom').readOnly(),
    /**
     * @property windowHeight
     * @type {number}
     * @private
     */
    windowHeight: 0,
    /**
     * @property topTriggerElement
     * @private
     */
    topTriggerElement: null,
    /**
     * @property bottomTriggerElement
     * @private
     */
    bottomTriggerElement: null,
    /**
     * @property offsetBottom
     * @type {number}
     * @private
     */
    offsetBottom: (0, _object.computed)('top', 'ownHeight', 'bottom', 'windowHeight', function () {
      var _this$getProperties = this.getProperties('top', 'ownHeight', 'bottom', 'windowHeight'),
        top = _this$getProperties.top,
        ownHeight = _this$getProperties.ownHeight,
        bottom = _this$getProperties.bottom,
        windowHeight = _this$getProperties.windowHeight;
      return windowHeight - top - ownHeight - bottom;
    }),
    /**
     * Dynamic style for the components element
     *
     * @property style
     * @type {string}
     * @private
     */
    style: (0, _object.computed)('isSticky', 'ownHeight', 'ownWidth', function () {
      var height = this.get('ownHeight');
      if (height > 0 && this.get('isSticky')) {
        return (0, _string.htmlSafe)("height: ".concat(height, "px;"));
      }
    }),
    /**
     * Dynamic style for the sticky container (`position: fixed`)
     *
     * @property containerStyle
     * @type {string}
     * @private
     */
    containerStyle: (0, _object.computed)('isStickyTop', 'isStickyBottom', 'top', 'bottom', 'ownWidth', function () {
      if (this.get('isStickyBottom')) {
        var style = "position: absolute; bottom: ".concat(this.get('bottom'), "px; width: ").concat(this.get('ownWidth'), "px");
        return (0, _string.htmlSafe)(style);
      }
      if (this.get('isStickyTop')) {
        var _style = "position: fixed; top: ".concat(this.get('top'), "px; width: ").concat(this.get('ownWidth'), "px");
        return (0, _string.htmlSafe)(_style);
      }
    }),
    /**
     * Add listener to update sticky element width on resize event
     * @method initResizeEventListener
     * @private
     */
    initResizeEventListener: function initResizeEventListener() {
      var _this = this;
      this._resizeListener = function () {
        return _this.debouncedUpdateDimension();
      };
      window.addEventListener('resize', this._resizeListener, false);
    },
    /**
     * @method removeResizeEventListener
     * @private
     */
    removeResizeEventListener: function removeResizeEventListener() {
      window.removeEventListener('resize', this._resizeListener, false);
    },
    _pollTask: function _pollTask() {
      this.updatePosition();
      this.initPollTask();
    },
    initPollTask: function initPollTask() {
      if (!testing) {
        this._pollTimer = (0, _runloop.later)(this, this._pollTask, 500);
      }
    },
    removePollTask: function removePollTask() {
      if (this._pollTimer) {
        (0, _runloop.cancel)(this._pollTimer);
      }
    },
    /**
     * @method debouncedUpdateDimension
     * @private
     */
    debouncedUpdateDimension: function debouncedUpdateDimension() {
      (0, _runloop.debounce)(this, this.updateDimension, 30);
    },
    /**
     * @method updateDimension
     * @private
     */
    updateDimension: function updateDimension() {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return false;
      }
      this.set('windowHeight', window.innerHeight);
      this.set('ownHeight', this.element.offsetHeight);
      this.set('ownWidth', this.element.offsetWidth);
    },
    updatePosition: function updatePosition() {
      var topTriggerElement = this.topTriggerElement,
        bottomTriggerElement = this.bottomTriggerElement;
      if (topTriggerElement) {
        this.set('parentTop', elementPosition(topTriggerElement, this.get('top'), 0));
      }
      if (bottomTriggerElement) {
        this.set('parentBottom', elementPosition(bottomTriggerElement, 0, this.get('offsetBottom')));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.updateDimension();
      // scheduleOnce('afterRender', this, this.updateDimension);
      this.initResizeEventListener();
      this.initPollTask();
    },
    willDestroyElement: function willDestroyElement() {
      this.removeResizeEventListener();
      this.removePollTask();
    },
    actions: {
      parentTopEntered: function parentTopEntered() {
        // console.log('parentTopEntered');
        this.set('parentTop', 'in');
      },
      parentTopExited: function parentTopExited() {
        // make sure we captured the right dimensions before getting sticky!
        // console.log('parentTopExited');
        this.updateDimension();
        this.updatePosition();
      },
      parentBottomEntered: function parentBottomEntered() {
        // console.log('parentBottomEntered');
        this.set('parentBottom', 'in');
      },
      parentBottomExited: function parentBottomExited() {
        // console.log('parentBottomExited');
        this.updatePosition();
      },
      registerTopTrigger: function registerTopTrigger(element) {
        this.topTriggerElement = element;
        this.updatePosition();
      },
      registerBottomTrigger: function registerBottomTrigger(element) {
        this.bottomTriggerElement = element;
        this.updatePosition();
      }
    }
  });
  _exports.default = _default;
});