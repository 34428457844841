define("ember-keyboard/listeners/key-events", ["exports", "ember-keyboard/utils/listener-name"], function (_exports, _listenerName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.keyDown = keyDown;
  _exports.keyPress = keyPress;
  _exports.keyUp = keyUp;
  function keyDown(keyCombo) {
    return (0, _listenerName.default)('keydown', keyCombo);
  }
  function keyPress(keyCombo) {
    return (0, _listenerName.default)('keypress', keyCombo);
  }
  function keyUp(keyCombo) {
    return (0, _listenerName.default)('keyup', keyCombo);
  }
});