define("torii/services/torii-session", ["exports", "ember", "rsvp", "@ember/service", "@ember/object/evented", "@ember/object", "torii/session/state-machine", "torii/lib/container-utils"], function (_exports, _ember, _rsvp, _service, _evented, _object, _stateMachine, _containerUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function lookupAdapter(container, authenticationType) {
    var adapter = container.lookup('torii-adapter:' + authenticationType);
    if (!adapter) {
      adapter = container.lookup('torii-adapter:application');
    }
    return adapter;
  }
  var _default = _service.default.extend(_ember.default._ProxyMixin, {
    state: null,
    stateMachine: (0, _object.computed)(function () {
      return (0, _stateMachine.default)(this);
    }),
    setupStateProxy: (0, _evented.on)('init', function () {
      var sm = this.get('stateMachine'),
        proxy = this;
      sm.on('didTransition', function () {
        proxy.set('content', sm.state);
        proxy.set('currentStateName', sm.currentStateName);
      });
    }),
    // Make these properties one-way.
    setUnknownProperty: function setUnknownProperty() {},
    open: function open(provider, options) {
      var owner = (0, _containerUtils.getOwner)(this),
        torii = (0, _containerUtils.getOwner)(this).lookup('service:torii'),
        sm = this.get('stateMachine');
      return new _rsvp.Promise(function (resolve) {
        sm.send('startOpen');
        resolve();
      }).then(function () {
        return torii.open(provider, options);
      }).then(function (authorization) {
        var adapter = lookupAdapter(owner, provider);
        return adapter.open(authorization);
      }).then(function (user) {
        sm.send('finishOpen', user);
        return user;
      }).catch(function (error) {
        sm.send('failOpen', error);
        return (0, _rsvp.reject)(error);
      });
    },
    fetch: function fetch(provider, options) {
      var owner = (0, _containerUtils.getOwner)(this),
        sm = this.get('stateMachine');
      return new _rsvp.Promise(function (resolve) {
        sm.send('startFetch');
        resolve();
      }).then(function () {
        var adapter = lookupAdapter(owner, provider);
        return adapter.fetch(options);
      }).then(function (data) {
        sm.send('finishFetch', data);
        return;
      }).catch(function (error) {
        sm.send('failFetch', error);
        return (0, _rsvp.reject)(error);
      });
    },
    close: function close(provider, options) {
      var owner = (0, _containerUtils.getOwner)(this),
        sm = this.get('stateMachine');
      return new _rsvp.Promise(function (resolve) {
        sm.send('startClose');
        resolve();
      }).then(function () {
        var adapter = lookupAdapter(owner, provider);
        return adapter.close(options);
      }).then(function () {
        sm.send('finishClose');
      }).catch(function (error) {
        sm.send('failClose', error);
        return (0, _rsvp.reject)(error);
      });
    }
  });
  _exports.default = _default;
});