define("common/templates/components/cd-map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LeafletMap
    @bounds={{lat-lng-bounds this.paddedBounds}}
    @doubleClickZoom={{@doubleClickZoom}}
    @dragging={{@dragging}}
    @onDragend={{this.onMoveEnd}}
    @onLoad={{this.onload}}
    @scrollWheelZoom={{false}}
    @zoomControl={{@zoomControl}}
    class="cd-map--map"
    ...attributes
    as |map|
  >
    <map.tile
      @attribution={{this.attribution}}
      @url={{get this this.view}}
    />
    {{#each @markers as |location index|}}
      {{yield map location index}}
    {{/each}}
    {{#if @circle}}
      <map.circle
        @color="#000000"
        @fillColor="#ffffff"
        @fillOpacity="0.35"
        @location={{@circle.location}}
        @opacity="0.4"
        @radius={{@circle.radius}}
        @weight="1"
      />
    {{/if}}
  </LeafletMap>
  */
  {
    "id": "GSz2JgXy",
    "block": "[[[8,[39,0],[[24,0,\"cd-map--map\"],[17,1]],[[\"@bounds\",\"@doubleClickZoom\",\"@dragging\",\"@onDragend\",\"@onLoad\",\"@scrollWheelZoom\",\"@zoomControl\"],[[28,[37,1],[[30,0,[\"paddedBounds\"]]],null],[30,2],[30,3],[30,0,[\"onMoveEnd\"]],[30,0,[\"onload\"]],false,[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,5,[\"tile\"]],null,[[\"@attribution\",\"@url\"],[[30,0,[\"attribution\"]],[28,[37,2],[[30,0],[30,0,[\"view\"]]],null]]],null],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,6]],null]],null],null,[[[1,\"    \"],[18,10,[[30,5],[30,7],[30,8]]],[1,\"\\n\"]],[7,8]],null],[41,[30,9],[[[1,\"    \"],[8,[30,5,[\"circle\"]],null,[[\"@color\",\"@fillColor\",\"@fillOpacity\",\"@location\",\"@opacity\",\"@radius\",\"@weight\"],[\"#000000\",\"#ffffff\",\"0.35\",[30,9,[\"location\"]],\"0.4\",[30,9,[\"radius\"]],\"1\"]],null],[1,\"\\n\"]],[]],null]],[5]]]]]],[\"&attrs\",\"@doubleClickZoom\",\"@dragging\",\"@zoomControl\",\"map\",\"@markers\",\"location\",\"index\",\"@circle\",\"&default\"],false,[\"leaflet-map\",\"lat-lng-bounds\",\"get\",\"each\",\"-track-array\",\"yield\",\"if\"]]",
    "moduleName": "common/templates/components/cd-map.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});