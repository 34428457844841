define("common/templates/components/cd-pagination", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <nav role={{this.navigation}} aria-label={{this.ariaLabel}} class="cd-pagination" ...attributes>
    {{#each this.pages as |page|}}
      <LinkTo
        aria-label="Go to {{page.ariaLabel}}"
        aria-current={{if page.currentPage "true" "false"}}
        aria-disabled={{if page.disabled "true" "false"}}
        @query={{dynamic-hash (array (hash key=(or this.queryParam "pageNumber") value=page.pageNumber))}}
        class="cd-pagination--page{{if page.disabled " cd-pagination--disabled-page"}}{{if page.currentPage " cd-pagination--current-page"}}{{if page.class (concat " " page.class)}}"
        rel={{page.rel}}
      >
        {{#if page.fontAwesomeIcon}}
          <FaIcon @icon={{page.fontAwesomeIcon}} @prefix="fas"/>
        {{else}}
          {{page.label}}
        {{/if}}
      </LinkTo>
    {{/each}}
  </nav>
  
  */
  {
    "id": "EGn9RaYf",
    "block": "[[[11,\"nav\"],[16,\"role\",[30,0,[\"navigation\"]]],[16,\"aria-label\",[30,0,[\"ariaLabel\"]]],[24,0,\"cd-pagination\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"pages\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],[[16,\"aria-label\",[29,[\"Go to \",[30,2,[\"ariaLabel\"]]]]],[16,\"aria-current\",[52,[30,2,[\"currentPage\"]],\"true\",\"false\"]],[16,\"aria-disabled\",[52,[30,2,[\"disabled\"]],\"true\",\"false\"]],[16,0,[29,[\"cd-pagination--page\",[52,[30,2,[\"disabled\"]],\" cd-pagination--disabled-page\"],[52,[30,2,[\"currentPage\"]],\" cd-pagination--current-page\"],[52,[30,2,[\"class\"]],[28,[37,4],[\" \",[30,2,[\"class\"]]],null]]]]],[16,\"rel\",[30,2,[\"rel\"]]]],[[\"@query\"],[[28,[37,5],[[28,[37,6],[[28,[37,7],null,[[\"key\",\"value\"],[[28,[37,8],[[30,0,[\"queryParam\"]],\"pageNumber\"],null],[30,2,[\"pageNumber\"]]]]]],null]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"fontAwesomeIcon\"]],[[[1,\"        \"],[8,[39,9],null,[[\"@icon\",\"@prefix\"],[[30,2,[\"fontAwesomeIcon\"]],\"fas\"]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,2,[\"label\"]]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"page\"],false,[\"each\",\"-track-array\",\"link-to\",\"if\",\"concat\",\"dynamic-hash\",\"array\",\"hash\",\"or\",\"fa-icon\"]]",
    "moduleName": "common/templates/components/cd-pagination.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});