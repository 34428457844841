define("torii/router-dsl-ext", ["ember", "@ember/routing/router", "torii/compat/get-router-lib"], function (_ember, _router, _getRouterLib) {
  "use strict";

  var proto = _ember.default.RouterDSL.prototype;
  var currentMap = null;
  proto.authenticatedRoute = function () {
    this.route.apply(this, arguments);
    currentMap.push(arguments[0]);
  };
  _router.default.reopen({
    _initRouterJs: function _initRouterJs() {
      currentMap = [];
      this._super.apply(this, arguments);
      var routerLib = (0, _getRouterLib.default)(this);
      routerLib.authenticatedRoutes = currentMap;
    }
  });
});