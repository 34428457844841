define("common/helpers/currency", ["exports", "@ember/component/helper", "local-flavor-clients/helpers/to-fixed"], function (_exports, _helper, _toFixed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currency = currency;
  _exports.default = void 0;
  function currency(params) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$forceCents = _ref.forceCents,
      forceCents = _ref$forceCents === void 0 ? false : _ref$forceCents;
    // `toFixed` does not cast values, so we want to ensure we're providing a number
    params = params.slice();
    if (isNaN(params[0])) {
      params[0] = 0;
    }
    return "$".concat((0, _toFixed.toFixed)(params, {
      digits: 2,
      trimWholeNumbers: !forceCents
    }));
  }
  var _default = (0, _helper.helper)(currency);
  _exports.default = _default;
});