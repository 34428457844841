define("ember-keyboard/utils/get-cmd-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(platform) {
    if (typeof FastBoot === 'undefined') {
      if (platform === undefined) {
        platform = navigator.platform;
      }
      if (platform.indexOf('Mac') > -1) {
        return 'meta';
      } else {
        return 'ctrl';
      }
    }
  }
});