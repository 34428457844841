define("common/templates/components/cd-form-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CdSelect
    ...attributes
    @arrowClass={{@arrowClass}}
    @errorMessages={{this.errorMessages}}
    @icon={{@icon}}
    @iconPrefix={{@iconPrefix}}
    @label={{@label}}
    @labelAttribute={{@labelAttribute}}
    @onchange={{@onchange}}
    @options={{@options}}
    @placeholder={{@placeholder}}
    @selected={{@selected}}
    @tooltip={{this.tooltip}}
    @valueAttribute={{@valueAttribute}}
    @wrapperClass={{@wrapperClass}}
  />
  */
  {
    "id": "fvX5fPVS",
    "block": "[[[8,[39,0],[[17,1]],[[\"@arrowClass\",\"@errorMessages\",\"@icon\",\"@iconPrefix\",\"@label\",\"@labelAttribute\",\"@onchange\",\"@options\",\"@placeholder\",\"@selected\",\"@tooltip\",\"@valueAttribute\",\"@wrapperClass\"],[[30,2],[30,0,[\"errorMessages\"]],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,0,[\"tooltip\"]],[30,11],[30,12]]],null]],[\"&attrs\",\"@arrowClass\",\"@icon\",\"@iconPrefix\",\"@label\",\"@labelAttribute\",\"@onchange\",\"@options\",\"@placeholder\",\"@selected\",\"@valueAttribute\",\"@wrapperClass\"],false,[\"cd-select\"]]",
    "moduleName": "common/templates/components/cd-form-select.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});