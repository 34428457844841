/* eslint-disable no-undef */

/*
  TODO: resolve these deprecations.
  Best way to do this is to change the handler to "warn" or "throw" and run the tests and resolve each one
*/

self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
  workflow: [
    { handler: "silence", matchId: "ember.built-in-components.import" },
    { handler: "silence", matchId: "ember-keyboard.first-responder-inputs" },
    { handler: "silence", matchId: "ember.built-in-components.reopen" },
    { handler: "silence", matchId: "implicit-injections" },
    { handler: "silence", matchId: "ember-data:record-lifecycle-event-methods" },
    { handler: "silence", matchId: "routing.transition-methods" },
    { handler: "silence", matchId: "ember-keyboard.old-propagation-model" },
    { handler: "silence", matchId: "this-property-fallback" },
    { handler: "silence", matchId: "ember-modifier.use-modify" },
    { handler: "silence", matchId: "ember-modifier.no-args-property" },
    { handler: "silence", matchId: "ember-modifier.no-element-property" },
    { handler: "silence", matchId: "deprecated-run-loop-and-computed-dot-access" },
    { handler: "silence", matchId: "ensure-safe-component.string" },
    { handler: "silence", matchId: "setting-on-hash" },
    { handler: "silence", matchId: "computed-property.override" },
    { handler: "silence", matchId: "ember-glimmer.link-to.positional-arguments" },
    { handler: "silence", matchId: "autotracking.mutation-after-consumption" },
    { handler: "silence", matchId: "globals-resolver" },
    { handler: "silence", matchId: "ember-cli-mirage-config-routes-only-export" },
    { handler: "silence", matchId: "ember-global" },
  ]
};
