define("common/templates/components/cd-autocomplete/trigger", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @extra.triggerIcon}}
    <FaIcon class="cd-autocomplete--icon {{@extra.triggerIconClass}}" @icon={{@extra.triggerIcon}} @prefix={{@extra.triggerIconPrefix}}/>
  {{else}}
    {{yield}}
  {{/if}}
  <Input
    aria-label={{@extra.ariaLabel}}
    aria-controls={{if this.select.isOpen @listboxId null}}
    autocomplete="off"
    class="cd-autocomplete--input"
    placeholder={{@placeholder}}
    @type="search"
    @value={{@select.searchText}}
    {{on-key 'Enter' this.handleEnter}}
    {{on 'focusin' this.handleFocus}}
    {{on 'focusout' @onBlur}}
    {{on 'input' @onInput}}
    {{on 'keyup' this.handleUp}}
  />
  */
  {
    "id": "04VuCDYs",
    "block": "[[[41,[30,1,[\"triggerIcon\"]],[[[1,\"  \"],[8,[39,1],[[16,0,[29,[\"cd-autocomplete--icon \",[30,1,[\"triggerIconClass\"]]]]]],[[\"@icon\",\"@prefix\"],[[30,1,[\"triggerIcon\"]],[30,1,[\"triggerIconPrefix\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,7,null],[1,\"\\n\"]],[]]],[8,[39,3],[[16,\"aria-label\",[30,1,[\"ariaLabel\"]]],[16,\"aria-controls\",[52,[30,0,[\"select\",\"isOpen\"]],[30,2],null]],[24,\"autocomplete\",\"off\"],[24,0,\"cd-autocomplete--input\"],[16,\"placeholder\",[30,3]],[4,[38,4],[\"Enter\",[30,0,[\"handleEnter\"]]],null],[4,[38,5],[\"focusin\",[30,0,[\"handleFocus\"]]],null],[4,[38,5],[\"focusout\",[30,5]],null],[4,[38,5],[\"input\",[30,6]],null],[4,[38,5],[\"keyup\",[30,0,[\"handleUp\"]]],null]],[[\"@type\",\"@value\"],[\"search\",[30,4,[\"searchText\"]]]],null]],[\"@extra\",\"@listboxId\",\"@placeholder\",\"@select\",\"@onBlur\",\"@onInput\",\"&default\"],false,[\"if\",\"fa-icon\",\"yield\",\"input\",\"on-key\",\"on\"]]",
    "moduleName": "common/templates/components/cd-autocomplete/trigger.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});