define("ember-in-viewport/mixins/in-viewport", ["exports", "@ember/polyfills", "@ember/object/mixin", "@ember/utils", "@ember/debug", "@ember/service", "@ember/object", "@ember/runloop", "@ember/object/computed", "@ember/application", "ember-in-viewport/utils/can-use-dom", "ember-in-viewport/utils/can-use-raf", "ember-in-viewport/utils/find-elem", "ember-in-viewport/utils/can-use-intersection-observer", "ember-in-viewport/utils/is-in-viewport", "ember-in-viewport/utils/check-scroll-direction"], function (_exports, _polyfills, _mixin, _utils, _debug, _service, _object, _runloop, _computed, _application, _canUseDom, _canUseRaf, _findElem, _canUseIntersectionObserver, _isInViewport, _checkScrollDirection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var rAFIDS = {};
  var lastDirection = {};
  var lastPosition = {};
  var _default = _mixin.default.create({
    /**
     * IntersectionObserverEntry
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry
     *
     * @property intersectionObserver
     * @default null
     */
    intersectionObserver: null,
    /**
     * @property _debouncedEventHandler
     * @default null
     */
    _debouncedEventHandler: null,
    /**
     * unbinding listeners will short circuit rAF
     *
     * @property _stopListening
     * @default false
     */
    _stopListening: false,
    rAFPoolManager: (0, _service.inject)('-in-viewport'),
    /**
     * @property viewportExited
     * @type Boolean
     */
    viewportExited: (0, _computed.not)('viewportEntered').readOnly(),
    init: function init() {
      // ensure this mixin runs first, then your component can override the options
      this._super.apply(this, arguments);
      var options = (0, _polyfills.assign)({
        viewportUseRAF: (0, _canUseRaf.default)(),
        viewportUseIntersectionObserver: (0, _canUseIntersectionObserver.default)(),
        viewportEntered: false,
        viewportListeners: []
      }, this._buildOptions());
      (0, _object.setProperties)(this, options);
      (0, _object.set)(this, '_evtListenerClosures', []);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!_canUseDom.default) {
        return;
      }
      var viewportEnabled = (0, _object.get)(this, 'viewportEnabled');
      if (viewportEnabled) {
        this._startListening();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._unbindListeners();
    },
    _buildOptions: function _buildOptions() {
      var defaultOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var owner = (0, _application.getOwner)(this);
      if (owner) {
        return (0, _polyfills.assign)(defaultOptions, owner.lookup('config:in-viewport'));
      }
    },
    _startListening: function _startListening() {
      var _this = this;
      this._setInitialViewport();
      this._addObserverIfNotSpying();
      this._bindScrollDirectionListener((0, _object.get)(this, 'viewportScrollSensitivity'));
      if (!(0, _object.get)(this, 'viewportUseIntersectionObserver') && !(0, _object.get)(this, 'viewportUseRAF')) {
        (0, _object.get)(this, 'viewportListeners').forEach(function (listener) {
          var context = listener.context,
            event = listener.event;
          context = (0, _object.get)(_this, 'scrollableArea') || context;
          _this._bindListeners(context, event);
        });
      }
    },
    _addObserverIfNotSpying: function _addObserverIfNotSpying() {
      if (!(0, _object.get)(this, 'viewportSpy')) {
        this.addObserver('viewportEntered', this, this._unbindIfEntered);
      }
    },
    _setInitialViewport: function _setInitialViewport() {
      var _this2 = this;
      if ((0, _object.get)(this, 'viewportUseIntersectionObserver')) {
        return (0, _runloop.scheduleOnce)('afterRender', this, function () {
          _this2._setupIntersectionObserver();
        });
      } else {
        return (0, _runloop.scheduleOnce)('afterRender', this, function () {
          _this2._setViewportEntered();
        });
      }
    },
    /**
     * @method _setupIntersectionObserver
     */
    _setupIntersectionObserver: function _setupIntersectionObserver() {
      var scrollableArea = (0, _object.get)(this, 'scrollableArea') ? document.querySelector((0, _object.get)(this, 'scrollableArea')) : null;
      var element = (0, _object.get)(this, 'element');
      if (!element) {
        return;
      }

      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      // IntersectionObserver takes either a Document Element or null for `root`
      var _this$viewportToleran = this.viewportTolerance,
        _this$viewportToleran2 = _this$viewportToleran.top,
        top = _this$viewportToleran2 === void 0 ? 0 : _this$viewportToleran2,
        _this$viewportToleran3 = _this$viewportToleran.left,
        left = _this$viewportToleran3 === void 0 ? 0 : _this$viewportToleran3,
        _this$viewportToleran4 = _this$viewportToleran.bottom,
        bottom = _this$viewportToleran4 === void 0 ? 0 : _this$viewportToleran4,
        _this$viewportToleran5 = _this$viewportToleran.right,
        right = _this$viewportToleran5 === void 0 ? 0 : _this$viewportToleran5;
      var options = {
        root: scrollableArea,
        rootMargin: "".concat(top, "px ").concat(right, "px ").concat(bottom, "px ").concat(left, "px"),
        threshold: (0, _object.get)(this, 'intersectionThreshold')
      };
      this.intersectionObserver = new IntersectionObserver((0, _runloop.bind)(this, this._onIntersection), options);
      this.intersectionObserver.observe(element);
    },
    /**
     * used by rAF and scroll event listeners to determine if mixin is in viewport
     * Remember to set `viewportSpy` to true if you want to continuously observe your element
     *
     * @method _setViewportEntered
     */
    _setViewportEntered: function _setViewportEntered() {
      var scrollableArea = (0, _object.get)(this, 'scrollableArea') ? document.querySelector((0, _object.get)(this, 'scrollableArea')) : null;
      var element = (0, _object.get)(this, 'element');
      if (!element) {
        return;
      }
      var height = scrollableArea ? scrollableArea.offsetHeight + scrollableArea.getBoundingClientRect().top : window.innerHeight;
      var width = scrollableArea ? scrollableArea.offsetWidth : window.innerWidth;
      var boundingClientRect = element.getBoundingClientRect();
      if (boundingClientRect) {
        this._triggerDidAccessViewport((0, _isInViewport.default)(boundingClientRect, height, width, (0, _object.get)(this, 'viewportTolerance')));
        if ((0, _object.get)(this, 'viewportUseRAF') && !(0, _object.get)(this, '_stopListening')) {
          var elementId = (0, _object.get)(this, 'elementId');
          rAFIDS[elementId] = (0, _object.get)(this, 'rAFPoolManager').add(elementId, (0, _runloop.bind)(this, this._setViewportEntered));
        }
      }
    },
    /**
     * callback provided to IntersectionObserver
     *
     * @method _onIntersection
     * @param {Array} - entries
     */
    _onIntersection: function _onIntersection(entries) {
      var isTearingDown = this.isDestroyed || this.isDestroying;
      var _entries = _slicedToArray(entries, 1),
        entry = _entries[0];
      var isIntersecting = entry.isIntersecting,
        intersectionRatio = entry.intersectionRatio;
      if (isIntersecting) {
        if (!isTearingDown) {
          (0, _object.set)(this, 'viewportEntered', true);
        }
        this.trigger('didEnterViewport');
      } else if (intersectionRatio <= 0) {
        // exiting viewport
        if (!isTearingDown) {
          (0, _object.set)(this, 'viewportEntered', false);
        }
        this.trigger('didExitViewport');
      }
    },
    /**
     * @method _triggerDidScrollDirection
     * @param contextEl
     * @param sensitivity
     */
    _triggerDidScrollDirection: function _triggerDidScrollDirection() {
      var contextEl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var sensitivity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      (false && !(contextEl) && (0, _debug.assert)('You must pass a valid context element to _triggerDidScrollDirection', contextEl));
      (false && !(sensitivity) && (0, _debug.assert)('sensitivity cannot be 0', sensitivity));
      var elementId = (0, _object.get)(this, 'elementId');
      var lastDirectionForEl = lastDirection[elementId];
      var lastPositionForEl = lastPosition[elementId];
      var newPosition = {
        top: contextEl.scrollTop,
        left: contextEl.scrollLeft
      };
      var scrollDirection = (0, _checkScrollDirection.default)(lastPositionForEl, newPosition, sensitivity);
      var directionChanged = scrollDirection !== lastDirectionForEl;
      if (scrollDirection && directionChanged && (0, _object.get)(this, 'viewportEntered')) {
        this.trigger('didScroll', scrollDirection);
        lastDirection[elementId] = scrollDirection;
      }
      lastPosition[elementId] = newPosition;
    },
    /**
     * @method _triggerDidAccessViewport
     * @param hasEnteredViewport
     */
    _triggerDidAccessViewport: function _triggerDidAccessViewport() {
      var hasEnteredViewport = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var viewportEntered = (0, _object.get)(this, 'viewportEntered');
      var didEnter = !viewportEntered && hasEnteredViewport;
      var didLeave = viewportEntered && !hasEnteredViewport;
      var triggeredEventName = '';
      if (didEnter) {
        triggeredEventName = 'didEnterViewport';
      }
      if (didLeave) {
        triggeredEventName = 'didExitViewport';
      }
      if ((0, _object.get)(this, 'viewportSpy') || !viewportEntered) {
        (0, _object.set)(this, 'viewportEntered', hasEnteredViewport);
      }
      this.trigger(triggeredEventName);
    },
    _unbindIfEntered: function _unbindIfEntered() {
      if (!(0, _object.get)(this, 'viewportSpy') && (0, _object.get)(this, 'viewportEntered')) {
        this._unbindListeners();
        this.removeObserver('viewportEntered', this, this._unbindIfEntered);
        (0, _object.set)(this, 'viewportEntered', false);
      }
    },
    /**
     * General utility function
     *
     * @method _debouncedEvent
     */
    _debouncedEvent: function _debouncedEvent(methodName) {
      var _this3 = this;
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      (false && !(methodName) && (0, _debug.assert)('You must pass a methodName to _debouncedEvent', methodName));
      (false && !((0, _utils.typeOf)(methodName) === 'string') && (0, _debug.assert)('methodName must be a string', (0, _utils.typeOf)(methodName) === 'string'));
      (0, _runloop.debounce)(this, function () {
        return _this3[methodName].apply(_this3, args);
      }, (0, _object.get)(this, 'viewportRefreshRate'));
    },
    _bindScrollDirectionListener: function _bindScrollDirectionListener() {
      var sensitivity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      (false && !(sensitivity) && (0, _debug.assert)('sensitivity cannot be 0', sensitivity));
      var contextEl = (0, _object.get)(this, 'scrollableArea') || window;
      var elem = (0, _findElem.default)(contextEl);
      this._debouncedEventHandler = this._debouncedEvent.bind(this, '_triggerDidScrollDirection', elem, sensitivity);
      elem.addEventListener('scroll', this._debouncedEventHandler, false);
    },
    _unbindScrollDirectionListener: function _unbindScrollDirectionListener() {
      var elementId = (0, _object.get)(this, 'elementId');
      var context = (0, _object.get)(this, 'scrollableArea') || window;
      var elem = (0, _findElem.default)(context);
      if (elem) {
        elem.removeEventListener('scroll', this._debouncedEventHandler, false);
        delete lastPosition[elementId];
        delete lastDirection[elementId];
      }
    },
    /**
     * Only if not using IntersectionObserver and rAF
     *
     * @method _bindListeners
     */
    _bindListeners: function _bindListeners() {
      var _this4 = this;
      var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      (false && !(context) && (0, _debug.assert)('You must pass a valid context to _bindListeners', context));
      (false && !(event) && (0, _debug.assert)('You must pass a valid event to _bindListeners', event));
      var elem = (0, _findElem.default)(context);
      var evtListener = function evtListener() {
        return _this4._debouncedEvent('_setViewportEntered');
      };
      this._evtListenerClosures.push({
        event: event,
        evtListener: evtListener
      });
      elem.addEventListener(event, evtListener);
    },
    /**
     * @method _unbindListeners
     */
    _unbindListeners: function _unbindListeners() {
      var _this5 = this;
      (0, _object.set)(this, '_stopListening', true);

      // 1.
      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(this.element);
      }

      // 2.
      if (!(0, _object.get)(this, 'viewportUseIntersectionObserver') && (0, _object.get)(this, 'viewportUseRAF')) {
        var elementId = (0, _object.get)(this, 'elementId');
        (0, _runloop.next)(this, function () {
          (0, _object.get)(_this5, 'rAFPoolManager').remove(elementId);
          delete rAFIDS[elementId];
        });
      }

      // 3.
      if (!(0, _object.get)(this, 'viewportUseIntersectionObserver') && !(0, _object.get)(this, 'viewportUseRAF')) {
        (0, _object.get)(this, 'viewportListeners').forEach(function (listener) {
          var context = listener.context,
            event = listener.event;
          context = (0, _object.get)(_this5, 'scrollableArea') || context;
          var elem = (0, _findElem.default)(context);
          var _ref = _this5._evtListenerClosures.find(function (closure) {
              return event === closure.event;
            }) || {},
            evtListener = _ref.evtListener;
          elem.removeEventListener(event, evtListener);
        });
      }

      // 4.
      this._unbindScrollDirectionListener();
    }
  });
  _exports.default = _default;
});