define("common/helpers/image-fallback", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.imageFallback = imageFallback;
  function imageFallback() {
    return "https://images.clipp.com/placeholders/".concat(Math.floor(Math.random() * 4) + 1, ".jpg");
  }
  var _default = (0, _helper.helper)(imageFallback);
  _exports.default = _default;
});