define("ember-route-action-helper/-private/internals", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ACTION = void 0;
  var ClosureActionModule;
  if ('ember-htmlbars/keywords/closure-action' in _ember.default.__loader.registry) {
    ClosureActionModule = _ember.default.__loader.require('ember-htmlbars/keywords/closure-action');
  } else if ('ember-routing-htmlbars/keywords/closure-action' in _ember.default.__loader.registry) {
    ClosureActionModule = _ember.default.__loader.require('ember-routing-htmlbars/keywords/closure-action');
  } else {
    ClosureActionModule = {};
  }
  var ACTION = ClosureActionModule.ACTION;
  _exports.ACTION = ACTION;
});