define("common/templates/components/cd-autocomplete/before-options", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    {{#if @extra.resultsTitle}}
      <div class="cd-autocomplete--results-title">
        {{@extra.resultsTitle}}
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "cK45aoMd",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,2,[\"resultsTitle\"]],[[[1,\"    \"],[10,0],[14,0,\"cd-autocomplete--results-title\"],[12],[1,\"\\n      \"],[1,[30,2,[\"resultsTitle\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@extra\"],false,[\"if\"]]",
    "moduleName": "common/templates/components/cd-autocomplete/before-options.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});