define("common/templates/components/cd-site-search/power-select-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (not-eq @group.groupName 'Search History')}}
    <li class="ember-power-select-group {{@group.class}}" aria-disabled={{if @group.disabled "true"}} role="option">
      <span class="ember-power-select-group-name">{{@group.groupName}}</span>
      {{yield}}
    </li>
  {{else}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "USklNZMe",
    "block": "[[[41,[28,[37,1],[[30,1,[\"groupName\"]],\"Search History\"],null],[[[1,\"  \"],[10,\"li\"],[15,0,[29,[\"ember-power-select-group \",[30,1,[\"class\"]]]]],[15,\"aria-disabled\",[52,[30,1,[\"disabled\"]],\"true\"]],[14,\"role\",\"option\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"ember-power-select-group-name\"],[12],[1,[30,1,[\"groupName\"]]],[13],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]]]],[\"@group\",\"&default\"],false,[\"if\",\"not-eq\",\"yield\"]]",
    "moduleName": "common/templates/components/cd-site-search/power-select-group.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});