define("common/templates/components/cd-form-textarea", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CdTextarea
    ...attributes
    @errorMessages={{this.errorMessages}}
    @icon={{@icon}}
    @iconPrefix={{@iconPrefix}}
    @label={{@label}}
    @tooltip={{this.tooltip}}
    @value={{@value}}
  />
  */
  {
    "id": "bQ6Zi7Qr",
    "block": "[[[8,[39,0],[[17,1]],[[\"@errorMessages\",\"@icon\",\"@iconPrefix\",\"@label\",\"@tooltip\",\"@value\"],[[30,0,[\"errorMessages\"]],[30,2],[30,3],[30,4],[30,0,[\"tooltip\"]],[30,5]]],null]],[\"&attrs\",\"@icon\",\"@iconPrefix\",\"@label\",\"@value\"],false,[\"cd-textarea\"]]",
    "moduleName": "common/templates/components/cd-form-textarea.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});