define("ember-keyboard/decorators/on-key", ["exports", "ember-keyboard/utils/listener-name"], function (_exports, _listenerName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = onKey;
  var DEFAULT_EVENT_NAME = 'keydown';
  function onKey(keyCombo) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (typeof arguments[1] === 'function') {
      return onKeyClassic(keyCombo, {
        event: DEFAULT_EVENT_NAME
      }, arguments[1]);
    }
    if (!opts.event) {
      opts.event = DEFAULT_EVENT_NAME;
    }
    if (typeof arguments[2] === 'function') {
      return onKeyClassic(keyCombo, opts, arguments[2]);
    } else {
      return onKeyDecorator(keyCombo, opts);
    }
  }
  function onKeyDecorator(keyCombo, opts) {
    // ES6 class
    return function (target, property, descriptor) {
      if (!Object.prototype.hasOwnProperty.call(target, 'keyboardHandlerNames')) {
        var parentKeyboardHandlerNames = target.parentKeyboardHandlerNames;
        // we need to assign because of the way mixins copy actions down when inheriting
        target.keyboardHandlerNames = parentKeyboardHandlerNames ? Object.assign({}, parentKeyboardHandlerNames) : {};
      }
      target.keyboardHandlerNames[(0, _listenerName.default)(opts.event, keyCombo)] = property;
      return descriptor;
    };
  }
  function onKeyClassic(keyCombo, opts, handler) {
    if (!handler._emberKeyboardOnKeyDecoratorData) {
      handler._emberKeyboardOnKeyDecoratorData = {
        listenerNames: []
      };
    }
    handler._emberKeyboardOnKeyDecoratorData.listenerNames.push((0, _listenerName.default)(opts.event, keyCombo));
    return handler;
  }
});