define("ember-batcher/batcher", ["exports", "@ember/test-waiters"], function (_exports, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mutateDOM = mutateDOM;
  _exports.readDOM = readDOM;
  _exports.visibilityChange = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var IS_BROWSER = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && (typeof document === "undefined" ? "undefined" : _typeof(document)) === 'object';
  var SCHEDULE_MACROTASK = function SCHEDULE_MACROTASK(callback) {
    return setTimeout(callback);
  };
  var readDOMWaiter = (0, _testWaiters.buildWaiter)('ember-batcher: readDOM');
  var mutateDOMWaiter = (0, _testWaiters.buildWaiter)('ember-batcher: mutateDOM');
  var reads = [];
  var mutations = [];
  var visibilityChange = function visibilityChange() {};
  _exports.visibilityChange = visibilityChange;
  var running = false;
  var scheduleFnExecuted = false;
  var rafRace = function rafRace(callback) {
    var executeCallback = function executeCallback() {
      if (!scheduleFnExecuted) {
        scheduleFnExecuted = true;
        callback();
      }
    };
    setTimeout(executeCallback, 20);
    return requestAnimationFrame(executeCallback);
  };
  var scheduleFn = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && typeof window.requestAnimationFrame === 'function' ? rafRace : SCHEDULE_MACROTASK;
  if (false /* DEBUG */ && IS_BROWSER) {
    _exports.visibilityChange = visibilityChange = function visibilityChange() {
      var hidden = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document.hidden;
      var hasQueuedWork = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {
        return reads.length > 0 && mutations.length > 0;
      };
      return function () {
        if (hidden && hasQueuedWork()) {
          throw new Error("Your browser tab is running in the background. ember-batcher's execution is not guaranteed in this environment");
        }
      };
    };
    document.addEventListener('visibilitychange', visibilityChange());
  }
  function run() {
    if (!running) {
      running = true;
      scheduleFn(function () {
        var i, l;
        for (i = 0, l = reads.length; i < l; i++) {
          var _ref = reads.pop(),
            _ref2 = _slicedToArray(_ref, 2),
            token = _ref2[0],
            readTask = _ref2[1];
          readTask();
          readDOMWaiter.endAsync(token);
        }
        for (i = 0, l = mutations.length; i < l; i++) {
          var _ref3 = mutations.pop(),
            _ref4 = _slicedToArray(_ref3, 2),
            _token = _ref4[0],
            mutateTask = _ref4[1];
          mutateTask();
          mutateDOMWaiter.endAsync(_token);
        }
        running = false;
        scheduleFnExecuted = false;
        if (mutations.length > 0 || reads.length > 0) {
          run();
        }
      });
    }
  }

  /**
   * Provides a mechanism to group DOM reads to minimize layout thrashing.
   *
   * @param readTask The function to call as part of the reads batch.
   */
  function readDOM(readTask) {
    var token = readDOMWaiter.beginAsync();
    reads.unshift([token, readTask]);
    run();
  }

  /**
   * Provides a mechanism to group DOM mutations to minimize layout thrashing.
   *
   * @param mutationTask The function to call as part of the mutations batch.
   */
  function mutateDOM(mutationTask) {
    var token = mutateDOMWaiter.beginAsync();
    mutations.unshift([token, mutationTask]);
    run();
  }
});