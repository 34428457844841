define("ember-keyboard/utils/is-key", ["exports", "ember-keyboard/utils/keyboard-listener", "ember-keyboard/utils/platform", "ember-keyboard/fixtures/key-maps", "ember-keyboard/fixtures/modifiers-array", "ember-keyboard/utils/get-mouse-name"], function (_exports, _keyboardListener, _platform, _keyMaps, _modifiersArray, _getMouseName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isKey;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var ALL_SYMBOL = '_all';
  function isKey(listenerOrListenerName, event) {
    var platform = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (0, _platform.default)();
    var listener;
    if (listenerOrListenerName instanceof _keyboardListener.default) {
      listener = listenerOrListenerName;
    } else if (typeof listenerOrListenerName === 'string') {
      listener = _keyboardListener.default.parse(listenerOrListenerName, platform);
    } else {
      throw new Error('Expected a `string` or `KeyCombo` as `keyComboOrKeyComboString` argument to `isKey`');
    }
    if (listener.type !== event.type) {
      return false;
    }
    if (isAll(listener)) {
      return true;
    }
    if (modifiersMatch(listener, event) && (keyOrCodeMatches(listener, event) || mouseButtonMatches(listener, event))) {
      return true;
    }
    return specialCaseMatches(listener, event, platform);
  }
  function isAll(listener) {
    return listener.keyOrCode === ALL_SYMBOL && listener.altKey === false && listener.ctrlKey === false && listener.metaKey === false && listener.shiftKey === false;
  }
  function modifiersMatch(listener, keyboardEvent) {
    return listener.type === keyboardEvent.type && listener.altKey === keyboardEvent.altKey && listener.ctrlKey === keyboardEvent.ctrlKey && listener.metaKey === keyboardEvent.metaKey && listener.shiftKey === keyboardEvent.shiftKey;
  }
  function keyOrCodeMatches(listener, keyboardEvent) {
    if (!(keyboardEvent instanceof KeyboardEvent)) {
      return false;
    }
    if (listener.keyOrCode === ALL_SYMBOL) {
      return true;
    }
    return listener.keyOrCode === keyboardEvent.code || listener.keyOrCode === keyboardEvent.key;
  }
  function mouseButtonMatches(listener, mouseEvent) {
    if (!(mouseEvent instanceof MouseEvent)) {
      return false;
    }
    if (listener.keyOrCode === ALL_SYMBOL) {
      return true;
    }
    return listener.keyOrCode === (0, _getMouseName.default)(mouseEvent.button);
  }
  function specialCaseMatches(keyboardListener, keyboardEvent, platform) {
    if (onlyModifiers([], keyboardListener) && onlyModifiers(['shift'], keyboardEvent)) {
      return keyboardEvent.key === keyboardListener.keyOrCode;
    }
    if (onlyModifiers(['shift'], keyboardListener) && onlyModifiers(['shift'], keyboardEvent)) {
      return rootKeyForShiftKey(keyboardEvent.key) === keyboardListener.keyOrCode;
    }
    if (platform === 'Macintosh' && onlyModifiers(['alt'], keyboardListener) && onlyModifiers(['alt'], keyboardEvent)) {
      return rootKeyForMacAltKey(keyboardEvent.key) === keyboardListener.keyOrCode;
    }
    if (platform === 'Macintosh' && onlyModifiers(['shift', 'alt'], keyboardListener) && onlyModifiers(['shift', 'alt'], keyboardEvent)) {
      return rootKeyForMacShiftAltKey(keyboardEvent.key) === keyboardListener.keyOrCode;
    }
    return false;
  }
  var ALL_MODIFIERS_EXCEPT_CMD = _modifiersArray.default.filter(function (m) {
    return m != 'cmd';
  });
  function onlyModifiers(names, obj) {
    var _iterator = _createForOfIteratorHelper(ALL_MODIFIERS_EXCEPT_CMD),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var modifier = _step.value;
        if (names.includes(modifier) && !obj["".concat(modifier, "Key")]) {
          return false;
        }
        if (!names.includes(modifier) && obj["".concat(modifier, "Key")]) {
          return false;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return true;
  }
  function rootKeyForShiftKey(key) {
    return _keyMaps.SHIFT_KEY_MAP[key] || key;
  }
  function rootKeyForMacAltKey(key) {
    return _keyMaps.MAC_ALT_KEY_MAP[key] || key;
  }
  function rootKeyForMacShiftAltKey(key) {
    return _keyMaps.MAC_SHIFT_ALT_KEY_MAP[key] || key;
  }
});