define("common/templates/components/cd-form-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CdInputToggle
    ...attributes
    @checked={{@checked}}
    @errorMessages={{this.errorMessages}}
    @icon={{@icon}}
    @iconPrefix={{@iconPrefix}}
    @label={{@label}}
    @wrapperClass={{@wrapperClass}}
    type="checkbox"
  />
  */
  {
    "id": "Kbu/Cl/C",
    "block": "[[[8,[39,0],[[17,1],[24,4,\"checkbox\"]],[[\"@checked\",\"@errorMessages\",\"@icon\",\"@iconPrefix\",\"@label\",\"@wrapperClass\"],[[30,2],[30,0,[\"errorMessages\"]],[30,3],[30,4],[30,5],[30,6]]],null]],[\"&attrs\",\"@checked\",\"@icon\",\"@iconPrefix\",\"@label\",\"@wrapperClass\"],false,[\"cd-input-toggle\"]]",
    "moduleName": "common/templates/components/cd-form-checkbox.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});