define("common/templates/components/cd-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @open}}
    <ModalDialog
      @containerClass={{@containerClass}}
      @containerClassNames={{array 'cd-dialog' @class}}
      @hasOverlay={{true}}
      @onClose={{@onClose}}
      @onClickOverlay={{@onClickOverlay}}
      @overlayClass={{@overlayClass}}
      @overlayClassNames={{array 'cd-dialog--overlay'}}
      @targetAttachmentClass=''
      @wrapperClass='cd-dialog--scroller{{if
        @wrapperClass
        (concat " " @wrapperClass)
      }}'
      @wrapperClassNames={{array 'cd-dialog--wrapper'}}
      ...attributes
    >
      {{#if this.canDismiss}}
        {{on-key 'Escape' (optional @onClose)}}
  
        {{#if @isNewCloseButton}}
          <button
            class='cd-dialog--close-button--xs'
            type='button'
            {{on 'click' @onClose}}
          >
            Cancel
          </button>
          <CdIconButton
            aria-label='Close Dialog'
            class='cd-dialog--close-button--md'
            @icon='xmark'
            onClick={{@onClose}}
          />
        {{else}}
          <CdIconButton
            aria-label='Close Dialog'
            class='cd-dialog--close-button'
            @icon='xmark'
            onClick={{@onClose}}
          />
        {{/if}}
      {{/if}}
  
      {{yield}}
    </ModalDialog>
  {{/if}}
  
  */
  {
    "id": "VbUJiv4+",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[17,2]],[[\"@containerClass\",\"@containerClassNames\",\"@hasOverlay\",\"@onClose\",\"@onClickOverlay\",\"@overlayClass\",\"@overlayClassNames\",\"@targetAttachmentClass\",\"@wrapperClass\",\"@wrapperClassNames\"],[[30,3],[28,[37,2],[\"cd-dialog\",[30,4]],null],true,[30,5],[30,6],[30,7],[28,[37,2],[\"cd-dialog--overlay\"],null],\"\",[29,[\"cd-dialog--scroller\",[52,[30,8],[28,[37,3],[\" \",[30,8]],null]]]],[28,[37,2],[\"cd-dialog--wrapper\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"canDismiss\"]],[[[1,\"      \"],[1,[28,[35,4],[\"Escape\",[28,[37,5],[[30,5]],null]],null]],[1,\"\\n\\n\"],[41,[30,9],[[[1,\"        \"],[11,\"button\"],[24,0,\"cd-dialog--close-button--xs\"],[24,4,\"button\"],[4,[38,6],[\"click\",[30,5]],null],[12],[1,\"\\n          Cancel\\n        \"],[13],[1,\"\\n        \"],[8,[39,7],[[24,\"aria-label\",\"Close Dialog\"],[24,0,\"cd-dialog--close-button--md\"],[16,\"onClick\",[30,5]]],[[\"@icon\"],[\"xmark\"]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,7],[[24,\"aria-label\",\"Close Dialog\"],[24,0,\"cd-dialog--close-button\"],[16,\"onClick\",[30,5]]],[[\"@icon\"],[\"xmark\"]],null],[1,\"\\n\"]],[]]]],[]],null],[1,\"\\n    \"],[18,10,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@open\",\"&attrs\",\"@containerClass\",\"@class\",\"@onClose\",\"@onClickOverlay\",\"@overlayClass\",\"@wrapperClass\",\"@isNewCloseButton\",\"&default\"],false,[\"if\",\"modal-dialog\",\"array\",\"concat\",\"on-key\",\"optional\",\"on\",\"cd-icon-button\",\"yield\"]]",
    "moduleName": "common/templates/components/cd-dialog.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});