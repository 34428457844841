define("common/decorators/action", ["exports", "@ember/object", "@datadog/browser-rum"], function (_exports, _object, _browserRum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = action;
  function action(target, name, descriptor) {
    var original = descriptor.value;
    descriptor.value = function () {
      if (typeof FastBoot !== 'undefined' || !_browserRum.datadogRum.getInternalContext()) {
        return original.call.apply(original, [this].concat(Array.prototype.slice.call(arguments)));
      }
      try {
        var data = {};
        if (this.parentView) {
          data['parent-view'] = this.parentView.constructor.name;
        }
        if (!name.match(/^(onIn|onResize|onOpen|onFocus|handle|did|willDestroy|updateVisibleSlides|log|register)/)) {
          _browserRum.datadogRum.addAction("".concat(this.constructor.name, ":").concat(name), {
            data: data
          });
        }
        return original.call.apply(original, [this].concat(Array.prototype.slice.call(arguments)));
      } catch (error) {
        _browserRum.datadogRum.addError(error);
        return original.call.apply(original, [this].concat(Array.prototype.slice.call(arguments)));
      }
    };
    return _object.action.call.apply(_object.action, [this].concat(Array.prototype.slice.call(arguments)));
  }
});