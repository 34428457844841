define("common/templates/components/cd-form-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CdInputText
    ...attributes
    @errorMessages={{this.errorMessages}}
    @icon={{@icon}}
    @iconPrefix={{@iconPrefix}}
    @label={{@label}}
    @pattern={{@pattern}}
    @tooltip={{this.tooltip}}
    @type="text"
    @value={{this.value}}
    @wrapperClass={{@wrapperClass}}
  />
  */
  {
    "id": "Qmw1lZTP",
    "block": "[[[8,[39,0],[[17,1]],[[\"@errorMessages\",\"@icon\",\"@iconPrefix\",\"@label\",\"@pattern\",\"@tooltip\",\"@type\",\"@value\",\"@wrapperClass\"],[[30,0,[\"errorMessages\"]],[30,2],[30,3],[30,4],[30,5],[30,0,[\"tooltip\"]],\"text\",[30,0,[\"value\"]],[30,6]]],null]],[\"&attrs\",\"@icon\",\"@iconPrefix\",\"@label\",\"@pattern\",\"@wrapperClass\"],false,[\"cd-input-text\"]]",
    "moduleName": "common/templates/components/cd-form-text.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});