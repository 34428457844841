define("torii/providers/-private/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadScript = loadScript;
  _exports.overrideLoadScript = overrideLoadScript;
  _exports.resetLoadScript = resetLoadScript;
  var alternativeLoadScript = false;
  function overrideLoadScript(state) {
    alternativeLoadScript = state;
  }
  function resetLoadScript() {
    alternativeLoadScript = false;
  }
  function loadScript(src) {
    if (alternativeLoadScript) {
      return alternativeLoadScript(src);
    }
    var scriptTag = document.createElement('script');
    var firstScriptTag = document.getElementsByTagName('script')[0];
    scriptTag.src = src;
    firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
  }
});