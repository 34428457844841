define("common/templates/components/cd-form-currency", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CdInputText
    ...attributes
    @icon="dollar-sign"
    @iconPrefix="far"
    @errorMessages={{this.errorMessages}}
    @label={{@label}}
    @oninput={{this.updateValue}}
    @tooltip={{this.tooltip}}
    @type="text"
    @value={{this.value}}
  />
  */
  {
    "id": "Ky+SV0Ds",
    "block": "[[[8,[39,0],[[17,1]],[[\"@icon\",\"@iconPrefix\",\"@errorMessages\",\"@label\",\"@oninput\",\"@tooltip\",\"@type\",\"@value\"],[\"dollar-sign\",\"far\",[30,0,[\"errorMessages\"]],[30,2],[30,0,[\"updateValue\"]],[30,0,[\"tooltip\"]],\"text\",[30,0,[\"value\"]]]],null]],[\"&attrs\",\"@label\"],false,[\"cd-input-text\"]]",
    "moduleName": "common/templates/components/cd-form-currency.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});