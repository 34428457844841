define("common/templates/components/cd-form-password", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CdInputText
    autocomplete="password"
    ...attributes
    autocapitalize="none"
    @errorMessages={{this.errorMessages}}
    @icon="lock"
    @iconPrefix={{@iconPrefix}}
    @label={{@label}}
    @type="password"
    @value={{@value}}
  />
  */
  {
    "id": "AqOdXD3P",
    "block": "[[[8,[39,0],[[24,\"autocomplete\",\"password\"],[17,1],[24,\"autocapitalize\",\"none\"]],[[\"@errorMessages\",\"@icon\",\"@iconPrefix\",\"@label\",\"@type\",\"@value\"],[[30,0,[\"errorMessages\"]],\"lock\",[30,2],[30,3],\"password\",[30,4]]],null]],[\"&attrs\",\"@iconPrefix\",\"@label\",\"@value\"],false,[\"cd-input-text\"]]",
    "moduleName": "common/templates/components/cd-form-password.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});