define("ember-keyboard/listeners/touch-events", ["exports", "ember-keyboard/utils/listener-name", "ember-keyboard/fixtures/modifiers-array"], function (_exports, _listenerName, _modifiersArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.touchEnd = touchEnd;
  _exports.touchStart = touchStart;
  function validateKeys(keysString) {
    var keys = keysString !== undefined ? keysString.split('+') : [];
    keys.forEach(function (key) {
      if (_modifiersArray.default.indexOf(key) === -1) {
        /* eslint no-console: ["error", { allow: ["error"] }] */
        console.error("`".concat(key, "` is not a valid key name"));
      }
    });
  }
  var formattedListener = function formattedListener(type, keysString) {
    validateKeys(keysString);
    return (0, _listenerName.default)(type, keysString);
  };
  function touchEnd(keys) {
    return formattedListener('touchEnd', keys);
  }
  function touchStart(keys) {
    return formattedListener('touchstart', keys);
  }
});