define("common/templates/components/cd-toast", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cd-toast--container" ...attributes>
    {{#each this.flashMessages.queue as |flashMessage|}}
      <FlashMessage @flash={{flashMessage}} as |component flash close|>
        {{#if (eq flash.type "link")}}
  
          <a class="cd-toast cd-toast--success cd-link--unstyled" href={{flash.link}} rel="noopener noreferrer" target="_blank">
            <FaIcon class="cd-toast--icon-success" @icon="circle-check"/>
            <div class="cd-toast--message">
              {{html-safe flash.message}}
            </div>
          </a>
        {{else}}
          <div class="cd-toast cd-toast--{{flash.type}}">
            {{#if (eq flash.type "success")}}
              <FaIcon class="cd-toast--icon-success" @icon="circle-check"/>
            {{else if (eq flash.type "error")}}
              <FaIcon class="cd-toast--icon-error" @icon="octagon-xmark"/>
            {{/if}}
            <div class="cd-toast--message">
              {{html-safe flash.message}}
            </div>
            <CdIconButton
              class="cd-toast--close"
              @icon="xmark"
              onClick={{fn this.close flash.type close}}
            />
          </div>
        {{/if}}
      </FlashMessage>
    {{/each}}
  </div>
  */
  {
    "id": "0pYdQI70",
    "block": "[[[11,0],[24,0,\"cd-toast--container\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"flashMessages\",\"queue\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@flash\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,4],[[30,4,[\"type\"]],\"link\"],null],[[[1,\"\\n        \"],[10,3],[14,0,\"cd-toast cd-toast--success cd-link--unstyled\"],[15,6,[30,4,[\"link\"]]],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n          \"],[8,[39,5],[[24,0,\"cd-toast--icon-success\"]],[[\"@icon\"],[\"circle-check\"]],null],[1,\"\\n          \"],[10,0],[14,0,\"cd-toast--message\"],[12],[1,\"\\n            \"],[1,[28,[35,6],[[30,4,[\"message\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[15,0,[29,[\"cd-toast cd-toast--\",[30,4,[\"type\"]]]]],[12],[1,\"\\n\"],[41,[28,[37,4],[[30,4,[\"type\"]],\"success\"],null],[[[1,\"            \"],[8,[39,5],[[24,0,\"cd-toast--icon-success\"]],[[\"@icon\"],[\"circle-check\"]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[[30,4,[\"type\"]],\"error\"],null],[[[1,\"            \"],[8,[39,5],[[24,0,\"cd-toast--icon-error\"]],[[\"@icon\"],[\"octagon-xmark\"]],null],[1,\"\\n          \"]],[]],null]],[]]],[1,\"          \"],[10,0],[14,0,\"cd-toast--message\"],[12],[1,\"\\n            \"],[1,[28,[35,6],[[30,4,[\"message\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[8,[39,7],[[24,0,\"cd-toast--close\"],[16,\"onClick\",[28,[37,8],[[30,0,[\"close\"]],[30,4,[\"type\"]],[30,5]],null]]],[[\"@icon\"],[\"xmark\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[3,4,5]]]]],[1,\"\\n\"]],[2]],null],[13]],[\"&attrs\",\"flashMessage\",\"component\",\"flash\",\"close\"],false,[\"each\",\"-track-array\",\"flash-message\",\"if\",\"eq\",\"fa-icon\",\"html-safe\",\"cd-icon-button\",\"fn\"]]",
    "moduleName": "common/templates/components/cd-toast.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});