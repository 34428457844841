define("ember-keyboard/utils/get-mouse-code", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMouseName;
  function getMouseName(buttonCode) {
    if ((0, _utils.isNone)(buttonCode)) return;
    switch (buttonCode) {
      case 'left':
        return 0;
      case 'middle':
        return 1;
      case 'right':
        return 2;
    }
  }
});