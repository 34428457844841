define("ember-svg-jar/inlined/prize-spinner-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M214 266L427.042.5H.958L214 266z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "428",
      "height": "266",
      "viewBox": "0 0 428 266",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});