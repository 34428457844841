define("torii/services/iframe", ["exports", "@ember/object/evented", "@ember/object", "torii/mixins/ui-service-mixin"], function (_exports, _evented, _object, _uiServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Iframe = _object.default.extend(_evented.default, _uiServiceMixin.default, {
    openRemote: function openRemote(url) {
      this.remote = document.createElement('iframe');
      this.remote.src = url;
      this.remote.id = 'torii-iframe';
      var iframeParent = '.torii-iframe-placeholder';
      document.querySelector(iframeParent).appendChild(this.remote);
    },
    closeRemote: function closeRemote() {
      this.remote.remove();
    },
    pollRemote: function pollRemote() {
      if (document.querySelector('#torii-iframe') === null) {
        this.trigger('didClose');
      }
    }
  });
  var _default = Iframe;
  _exports.default = _default;
});